import * as React from "react";
import { Modal, Form, Input, Button, Popconfirm } from "antd";
import { useDispatch } from "react-redux";
import { deleteHealthcareProvider } from "store";
import useCheckPermission from "hooks/useCheckPermission";
import notification from "components/jkt/global/openNotification";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import { checkedData } from "utils/checkedData";
import { convertedFullName } from "utils/convertedFullName";
import {
  createHealthcareProvider as apiCreateHealthcareProvider,
  updateHealthcareProvider,
} from "store";

const CreateUpdateHealthcareProvider = (props) => {
  const {
    handleCancel,
    openCreateUpdate,
    handleSubmit,
    isForUpdate,
    idForUpdate,
  } = props;
  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToDelete = handleVerifyPermission(
    "Laboratory:HealthcareProvider:Delete"
  );

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const physicianName = Form.useWatch("physicianName", form);

  const LoadUpdateData = React.useCallback(() => {
    form.setFieldsValue({
      physicianName: `${idForUpdate.firstName} ${checkedData(
        idForUpdate.lastName
      )}`,
    });
  }, [form, idForUpdate]);

  React.useEffect(() => {
    if (isForUpdate) {
      LoadUpdateData();
    }
  }, [isForUpdate, LoadUpdateData]);

  const clearFormInputs = () => {
    form.setFieldsValue({
      physicianName: "",
    });
  };

  const closeModal = () => {
    clearFormInputs();
    handleCancel();
  };

  const onFinish = (values) => {
    if (isForUpdate) {
      if (
        updateHealthcareProvider.pending().type ===
        "healthcareProviders/update-healthcare-provider/pending"
      ) {
        setLoading(true);
      }
      const { firstName, lastName } = convertedFullName(physicianName);

      dispatch(
        updateHealthcareProvider({
          id: idForUpdate.id,
          firstName: firstName,
          lastName: lastName,
        })
      ).then((val) => {
        const responseData = val?.payload?.data;

        if (val.payload?.success) {
          handleSubmit();
          clearFormInputs();
          setLoading(false);
          notification.success({
            message: "Updated Healthcare Provider",
            description: `Successfully updated ${
              responseData?.firstName
            } ${checkedData(responseData?.lastName)}`,
          });
        }
        if (!val.payload?.success) {
          notification.error({
            message: "Failed to Update Healthcare Provider",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
          setLoading(false);
        }
      });
    } else {
      if (
        apiCreateHealthcareProvider.pending().type ===
        "healthcareProviders/create-healthcare-provider/pending"
      ) {
        setLoading(true);
      }
      const { firstName, lastName } = convertedFullName(physicianName);

      dispatch(
        apiCreateHealthcareProvider({
          firstName: firstName,
          lastName: lastName,
        })
      ).then((val) => {
        const responseData = val?.payload?.data;
        if (val.payload?.success) {
          handleSubmit();
          clearFormInputs();
          setLoading(false);
          notification.success({
            message: "Created Healthcare Provider",
            description: `Successfully created ${
              responseData?.firstName
            } ${checkedData(responseData?.lastName)}`,
          });
        }

        if (!val.payload?.success) {
          notification.error({
            message: "Failed to Create Healthcare Provider",
            description: errorMessagesDescription(
              val.payload?.response.data?.errorMessages
            ),
          });
          setLoading(false);
        }
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    errorInfo.errorFields.map((item) => {
      return notification.error({
        message: "Required Field",
        description: item?.errors[0].props.children,
      });
    });
  };

  const handleClickDelete = () => {
    //HANDLE LOADING
    if (
      deleteHealthcareProvider.pending().type ===
      "healthcareProviders/delete-healthcare-provider/pending"
    ) {
      setLoading(true);
    }
    dispatch(deleteHealthcareProvider(idForUpdate.id)).then((val) => {
      if (val.payload?.success) {
        handleSubmit();
        setLoading(false);
        notification.success({
          message: "Deleted Healthcare Provider",
          description: `Successfully deleted ${
            idForUpdate.firstName + " " + checkedData(idForUpdate.lastName)
          }`,
        });
      }
      if (!val.payload?.success) {
        notification.error({
          message: "Failed to Delete Healthcare Provider",
          description: errorMessagesDescription(
            val.payload?.response.data.errorMessages
          ),
        });
        setLoading(false);
      }
    });
  };

  return (
    <Modal
      open={openCreateUpdate}
      title={
        isForUpdate
          ? "Update Healthcare Provider"
          : "Create Healthcare Provider"
      }
      onCancel={closeModal}
      footer={null}
    >
      <Form
        name="healthcareProvider"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        {/* First Name */}
        <Form.Item
          className="mt-7"
          label="Physician Name"
          name="physicianName"
          rules={[
            {
              required: true,
              message: <i>{"Please input Physician Name."}</i>,
            },
          ]}
        >
          <Input placeholder="Physician Name" />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ margin: 2 }}
            className="submitBtn"
          >
            Submit
          </Button>
          {accessRightsToDelete && isForUpdate ? (
            <Popconfirm
              title="Delete the healthcare provider"
              description="Are you sure to delete?"
              onConfirm={handleClickDelete}
              okText="Yes"
              cancelText="No"
              okButtonProps={{ loading: loading, className: "submitBtn" }}
            >
              <Button loading={loading} type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          ) : null}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUpdateHealthcareProvider;
