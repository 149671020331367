import * as React from "react";
import {
  Grid,
  Typography,
  InputLabel,
  FormControl,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import { userRegister } from "store";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import decodedUserDetails from "utils/decodedUserDetails";
import notification from "components/jkt/global/openNotification";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";

const RegisterNewUser = () => {
  const { handleSubmit, register, reset, watch } = useForm({ mode: "all" });
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const username = watch("username") || "";
  const email = watch("email") || "";
  const displayName = watch("displayName") || "";
  const disableSubmitButton = !username || !email || !displayName;
  const isRoleHasClientAdmin =
    decodedUserDetails()?.role?.includes("ClientAdmin");

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    if (userRegister.pending().type === "auth/register-new-user/pending") {
      setIsLoading(true);
    }
    dispatch(userRegister(data)).then((res) => {
      const isFetchSuccess = res?.payload?.success;
      if (isFetchSuccess) {
        notification.success({
          message: "Created User",
          description: `Successfully created ${data.displayName}`,
        });
        reset({
          username: "",
          email: "",
          displayName: "",
          password: "",
        });
        setIsLoading(false);
      }
      if (!isFetchSuccess) {
        notification.error({
          message: "Failed to create user",
          description: errorMessagesDescription(
            res?.payload?.data?.errorMessages
          ),
        });
        setIsLoading(false);
      }
    });
  };

  React.useEffect(() => {
    if (!isRoleHasClientAdmin) {
      navigate(-1);
    }
  }, [isRoleHasClientAdmin, navigate]);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={3}
      sx={{
        width: { xs: "auto", sm: "auto", md: "auto", lg: "33rem", xl: "33rem" },
        px: 2,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography
        component="h1"
        variant="h4"
        sx={{
          fontWeight: "bold",
          width: "max-content",
          mx: "auto",
          my: 2,
          fontSize: {
            xs: "1.5rem",
            sm: "1.5rem",
            md: "1.8rem",
            lg: "1.8rem",
            xl: "1.8rem",
          },
          position: "relative",
          "::before": {
            content: "''",
            position: "absolute",
            left: 0,
            right: 0,
            height: "4px",
            bottom: "-4px",
            width: "20%",
            backgroundColor: "defaultBlue.main",
            borderRadius: "1rem",
            margin: "0 auto",
          },
        }}
      >
        Register New User
      </Typography>

      <FormControl required fullWidth variant="outlined" sx={{ mt: 2 }}>
        <InputLabel htmlFor="outlined-adornment-username">Username</InputLabel>
        <OutlinedInput
          autoComplete="off"
          id="outlined-adornment-username"
          type="text"
          name="username"
          label="Username"
          {...register("username")}
          required
        />
      </FormControl>

      <FormControl required fullWidth variant="outlined" sx={{ mt: 2 }}>
        <InputLabel htmlFor="outlined-adornment-email">
          Email Address
        </InputLabel>
        <OutlinedInput
          autoComplete="off"
          id="outlined-adornment-email"
          type="email"
          name="email"
          label="Email Address"
          {...register("email")}
          required
        />
      </FormControl>

      <FormControl required fullWidth variant="outlined" sx={{ mt: 2 }}>
        <InputLabel htmlFor="outlined-adornment-displayName">
          Display Name
        </InputLabel>
        <OutlinedInput
          autoComplete="off"
          id="outlined-adornment-displayName"
          type="displayName"
          name="displayName"
          label="Display Name"
          {...register("displayName")}
          required
        />
      </FormControl>

      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
        <InputLabel htmlFor="outlined-adornment-password">
          Temporary Password
        </InputLabel>
        <OutlinedInput
          autoComplete="off"
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          name="password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Temporary Password"
          {...register("password")}
        />
      </FormControl>

      <Grid container>
        <LoadingButton
          disabled={disableSubmitButton}
          fullWidth
          type="submit"
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
          loading={isLoading}
          loadingPosition="end"
        >
          {isLoading ? "Submitting..." : "Submit"}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
export default RegisterNewUser;
