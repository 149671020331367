import { Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clientLookUp } from "store";

const ClientFilter = ({ handleSelectClientId }) => {
  const [clientsData, setClientsData] = useState([]);
  const dispatch = useDispatch();

  const handleFetchClientsData = useCallback(() => {
    dispatch(clientLookUp()).then((res) => {
      const isFetchSuccessful = res?.payload?.success;
      if (isFetchSuccessful) {
        const clientOptions = res?.payload?.data?.map((client) => ({
          value: client.id,
          label: client.clientName,
          data: client,
        }));
        setClientsData(clientOptions);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    handleFetchClientsData();
  }, []);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      allowClear
      virtual={false}
      showSearch
      placeholder="Select or search client"
      optionFilterProp="children"
      onChange={handleSelectClientId}
      filterOption={filterOption}
      options={clientsData}
    />
  );
};

export default ClientFilter;
