import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: 0,
  physicianFullName: "",
  firstName: "",
  lastName: "",
};

const physicianInputSlice = createSlice({
  name: "physicianInputs",
  initialState,
  reducers: {
    handleChangePhysicianFields: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { handleChangePhysicianFields } = physicianInputSlice.actions;
export const physicianInputReducer = physicianInputSlice.reducer;
