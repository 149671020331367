import * as React from "react";
import { Grid, Button, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { Table, Input, ConfigProvider, Button as BtnAntD, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CreateUpdateUser from "./createUpdateUser";
import useDebounce from "hooks/useDebounce";
import { EmptyData } from "../global/emptyData";
import DirectionFilter from "./filterOptions/directionFilter";
import AssortedFilter from "./filterOptions/assortedFilter";
import StatusFilter from "./filterOptions/statusFilter";
import { getAllUsers, clientLookUp } from "store";
import { useCurrentHeight } from "utils/getScreenSize";

const GetAllUserLists = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const debouncedInputValue = useDebounce(searchText);
  const [sortStr, setSortStr] = React.useState("userName");
  const [sortDirection, setSortDirection] = React.useState(0);
  const [selectedUserStatus, setSelectedUserStatus] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [usersData, setUsersData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isForUpdate, setIsForUpdate] = React.useState(false);
  const [idForUpdate, setIdForUpdate] = React.useState(0);
  const [clientsData, setClientsData] = React.useState([]);
  // const [clientOptions, setClientOptions] = React.useState([]);
  // const [clientDetails, setClientDetails] = React.useState({});
  // const [selectedClientId, setSelectedClientId] = React.useState(null);
  // const isClientAbcLab =
  //   clientDetails?.clientCode === "ABC001" &&
  //   clientDetails?.clientName.toLowerCase() === "abc laboratories";

  // const handleFetchClientsData = React.useCallback(() => {
  //   dispatch(clientLookUp()).then((res) => {
  //     const isFetchSuccessful = res?.payload?.success;
  //     if (isFetchSuccessful) {
  //       const clientOptions = res?.payload?.data?.map((client) => ({
  //         value: client.id,
  //         label: client.clientName,
  //         data: client,
  //       }));
  //       setClientOptions(clientOptions);
  //     }
  //   });
  // }, [dispatch]);

  // React.useEffect(() => {
  //   handleFetchClientsData();
  // }, []);

  // //Fetching of client details
  // React.useEffect(() => {
  //   dispatch(getClientInfo()).then((res) => {
  //     if (res.type === "client/get-client-info/fulfilled") {
  //       setClientDetails(res.payload.data);
  //     }
  //   });
  // }, [clientId, dispatch]);

  const LoadData = React.useCallback(() => {
    //HANDLE LOADING
    if (getAllUsers.pending().type === "user/get-all-users/pending") {
      setIsLoading(true);
    }

    dispatch(
      getAllUsers({
        search: debouncedInputValue,
        pageSize: pageSize,
        pageNumber: currentPage,
        sortField: sortStr,
        sortDirection: sortDirection,
        userStatus: selectedUserStatus,
      })
    ).then((element) => {
      if (element.type === "user/get-all-users/fulfilled") {
        setTotal(element.payload.data.totalPages * pageSize);
        dispatch(clientLookUp()).then((valClient) => {
          setIsLoading(false);
          if (valClient.type === "client/client-lookup/fulfilled") {
            const newUserData = element.payload.data.items.map((userData) => {
              return {
                ...userData,
                clientName: valClient.payload.data.find(
                  (cl) => cl.id === userData.clientId
                )?.clientName,
              };
            });
            setClientsData(
              valClient?.payload?.data?.sort((a, b) =>
                a.clientName.localeCompare(b.clientName)
              )
            );
            setUsersData(newUserData);
          }
        });
      }
    });
  }, [
    dispatch,
    debouncedInputValue,
    pageSize,
    sortStr,
    currentPage,
    sortDirection,
    selectedUserStatus,
  ]);

  React.useEffect(() => {
    LoadData();
  }, [dispatch, LoadData]);

  const onChange = (page) => {
    setCurrentPage(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };
  const handleChangeSearch = (val) => {
    setIsLoading(true);
    setSearchText(val.target.value);
    setCurrentPage(1);
  };

  const renderEmptyData = () => (
    <EmptyData
      description="No User Detected"
      action={showCreateUpdate}
      btnTitle="Add New User"
      renderButton={true}
    />
  );

  //MODAL CREATE UPDATE
  const [openCreateUpdate, setOpenCreateUpdate] = React.useState(false);

  const handleCancel = () => {
    setOpenCreateUpdate(false);
    setIsForUpdate(false);
    setIdForUpdate(0);
  };
  const showCreateUpdate = () => {
    setOpenCreateUpdate(true);
  };
  const handleSubmit = () => {
    LoadData();
    setOpenCreateUpdate(false);
    setIdForUpdate(0);
    setIsForUpdate(false);
  };

  const handleUpdate = (id) => {
    setIsForUpdate(true);
    setIdForUpdate(id);
  };

  const handleChangeSortField = (value) => {
    setSortStr(value);
  };

  // const handleSelectClientId = (value) => {
  //   setSelectedClientId(value);
  //   setCurrentPage(1);
  // };

  const handleChangeSortDirection = (value) => {
    setSortDirection(value);
  };

  const handleChangeUserStatus = (value) => {
    setSelectedUserStatus(value);
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      width: 100,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 120,
    },
    {
      title: "Display Name",
      dataIndex: "displayName",
      key: "displayName",
      width: 50,
    },
    {
      title: "Client",
      dataIndex: "clientName",
      key: "clientName",
      width: 100,
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      width: 150,
      render: (rec, row) => (
        <Box>
          {rec?.map((role) => (
            <Tag color="blue" key={role}>
              {role}
            </Tag>
          ))}
        </Box>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      width: 50,
      render: (rec, row) => (
        <Tag color={row.isActive ? "success" : "red"}>
          {row.isActive ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 50,
      render: (rec, row) => (
        <>
          <BtnAntD
            size="small"
            icon={<EditIcon fontSize="inherit" />}
            className="hover:bg-orange-500 bg-orange-600"
            style={{
              border: "1px solid #ed6c02",
              color: "white",
            }}
            onClick={() => {
              handleUpdate(row);
              showCreateUpdate();
            }}
          >
            Update
          </BtnAntD>
        </>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2} lg={2}>
          <Input
            size=""
            placeholder="Search User"
            prefix={<SearchOutlined />}
            onChange={handleChangeSearch}
            value={searchText}
          />
        </Grid>
        {/* <Grid item xs={12} md={2} lg={2}>
          {isClientAbcLab && (
            <ClientFilter
              clientOptions={clientOptions}
              handleSelectClientId={handleSelectClientId}
            />
          )}
        </Grid> */}
        <Grid item xs={12} md={3} lg={3}>
          <AssortedFilter
            defaultValue={sortStr}
            handleChangeSortField={handleChangeSortField}
          />
          <DirectionFilter
            defaultValue={sortDirection}
            handleChangeSortDirection={handleChangeSortDirection}
          />
          <StatusFilter
            selectedUserStatus={selectedUserStatus}
            handleChangeUserStatus={handleChangeUserStatus}
          />
        </Grid>
        {usersData.length !== 0 ? (
          <Grid item xs={12} md={4} lg={4}>
            <Button
              startIcon={<AddOutlinedIcon />}
              variant="text"
              onClick={showCreateUpdate}
            >
              New User
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={columns}
          dataSource={usersData}
          loading={isLoading}
          size="small"
          pagination={{
            total: total,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            current: currentPage,
            onChange: onChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>
      <CreateUpdateUser
        handleCancel={handleCancel}
        openCreateUpdate={openCreateUpdate}
        handleSubmit={handleSubmit}
        isForUpdate={isForUpdate}
        idForUpdate={idForUpdate}
        clientData={clientsData}
      />
    </>
  );
};
export default GetAllUserLists;
