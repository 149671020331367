import * as React from "react";
import { Modal, Form, Input, Button, Popconfirm, Select, Switch } from "antd";
import { useDispatch } from "react-redux";
import AssignClaims from "../role/assignClaims";
import { getRoles, updateUser, createUser, deleteUser } from "store";
import notification from "components/jkt/global/openNotification";
import useSelectDropdownLoader from "hooks/useSelectDropdownLoader";

const CreateUpdateUser = (props) => {
  const {
    handleCancel,
    openCreateUpdate,
    handleSubmit,
    isForUpdate,
    idForUpdate,
    clientData,
  } = props;

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [roleClaims, setRoleClaims] = React.useState([]);
  //USER CLAIMS ASSETS
  const [openUserClaims, setOpenUserClaims] = React.useState(false);
  const { onDropdownVisibleChange, onBlur, isSelectOpening } =
    useSelectDropdownLoader();

  const handleUserClaims = () => {
    setOpenUserClaims(!openUserClaims);
  };

  const LoadUpdateData = React.useCallback(() => {
    form.setFieldsValue({
      displayName: idForUpdate.displayName,
      clientId: idForUpdate.clientId,
      userName: idForUpdate.userName,
      email: idForUpdate.email,
      isActive: idForUpdate.isActive,
      forcePasswordChange: idForUpdate.forcePasswordChange,
      roles: idForUpdate.roles,
    });
  }, [form, idForUpdate]);

  React.useEffect(() => {
    dispatch(getRoles()).then((val) => {
      if (val.type === "rolesClaims/get-roles/fulfilled") {
        setRoleClaims(val.payload.data);
      }
    });

    if (isForUpdate) {
      LoadUpdateData();
    }
  }, [dispatch, isForUpdate, LoadUpdateData]);

  const onFinish = (values) => {
    if (isForUpdate) {
      if (updateUser.pending().type === "user/update-user/pending") {
        setLoading(true);
      }
      dispatch(updateUser([idForUpdate.id, values])).then((val) => {
        if (val.type === "user/update-user/fulfilled") {
          if (val.payload?.success) {
            handleSubmit();
            setLoading(false);
            notification.success({
              message: "Updated User",
              description: `Successfully updated ${values.displayName}`,
            });
          } else {
            setLoading(false);
            val.payload.response.data.errorMessages.forEach((element) => {
              notification.error({
                message: "Updated User",
                description: element,
              });
            });
          }
        }
      });
    } else {
      if (createUser.pending().type === "user/create-users/pending") {
        setLoading(true);
      }
      dispatch(createUser(values)).then((val) => {
        if (val.type === "user/create-users/fulfilled") {
          if (val.payload?.success) {
            handleSubmit();
            setLoading(false);
            notification.success({
              message: "Created User",
              description: `Successfully created ${values.displayName}`,
            });
          } else {
            setLoading(false);

            val.payload.response.data.errorMessages.forEach((element) => {
              notification.error({
                message: "Failed to create user",
                description: element,
              });
            });
          }
        }
      });
    }
  };

  const handleHideModal = () => {
    handleCancel();
    form.setFieldsValue({
      displayName: "",
      userName: "",
      email: "",
      clientId: "",
      roles: [],
    });
  };

  const handleClickDelete = () => {
    //HANDLE LOADING
    if (deleteUser.pending().type === "user/delete-user/pending") {
      setLoading(true);
    }
    dispatch(deleteUser(idForUpdate.id)).then((val) => {
      if (val.type === "user/delete-user/fulfilled") {
        handleSubmit();
        setLoading(false);
        notification.success({
          message: "Deleted User",
          description: `Successfully deleted ${idForUpdate.displayName}`,
        });
      }
    });
  };

  const clientOptions = clientData.map((element) => {
    return { label: element.displayName, value: element.id };
  });

  const roleOptions = roleClaims.map((element) => {
    return { label: element.name, value: element.name };
  });

  return (
    <Modal
      open={openCreateUpdate}
      title={isForUpdate ? "Update User" : "Create User"}
      onCancel={handleHideModal}
      footer={null}
    >
      <Form
        name="user"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        {/*  User Name */}
        <Form.Item
          label="User Name"
          name="userName"
          rules={[
            {
              required: true,
              message: <i>{"Please input User Name."}</i>,
            },
          ]}
        >
          <Input autoComplete="new-email" placeholder="User Name" />
        </Form.Item>

        {/*  Password */}
        {!isForUpdate ? (
          <Form.Item label="Temporary Password" name="password">
            <Input.Password
              autoComplete="new-password"
              placeholder="Temporary Password"
            />
          </Form.Item>
        ) : null}

        {/*  Email */}
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: <i>{"Please input Email."}</i>,
              type: "email",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        {/*  Display Name */}
        <Form.Item
          label="Display Name"
          name="displayName"
          rules={[
            {
              required: true,
              message: <i>{"Please input Display Name."}</i>,
            },
          ]}
        >
          <Input placeholder="Display Name" />
        </Form.Item>

        {/* Client */}
        <Form.Item
          label="Client"
          name="clientId"
          rules={[
            {
              required: true,
              message: <i>{"Please input Client."}</i>,
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            virtual={false}
            options={clientOptions}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            placeholder={"Client"}
            onDropdownVisibleChange={onDropdownVisibleChange}
            onBlur={onBlur}
            loading={isSelectOpening}
          />
        </Form.Item>

        {/* Status */}
        {isForUpdate && (
          <Form.Item label="Status" valuePropName="checked" name="isActive">
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        )}

        {/* Force Change Password */}
        {isForUpdate && (
          <Form.Item
            label="Force Password Change"
            valuePropName="checked"
            name="forcePasswordChange"
          >
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked
            />
          </Form.Item>
        )}

        {/* Roles */}
        <Form.Item
          name="roles"
          label="Roles"
          rules={[
            {
              required: true,
              message: <i>{"Please pick atleast one role!"}</i>,
              type: "array",
            },
          ]}
        >
          <Select
            virtual={false}
            mode="multiple"
            placeholder="Role"
            options={roleOptions}
          />
        </Form.Item>

        {isForUpdate ? (
          <Modal
            open={openUserClaims}
            title={"User Claims"}
            onCancel={handleUserClaims}
            footer={null}
          >
            <AssignClaims selectedData={idForUpdate} type={"user"} />
          </Modal>
        ) : null}

        {/* Submit Button */}
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ margin: 2 }}
            className="submitBtn"
          >
            Submit
          </Button>
          {isForUpdate ? (
            <>
              <Button
                loading={loading}
                style={{ margin: 2 }}
                onClick={handleUserClaims}
                className="bg-green-600 border-green-600 text-white hover:enabled:bg-green-400 hover:enabled:text-white hover:enabled:border-green-400"
              >
                User Claims
              </Button>
              <Popconfirm
                title="Delete the user"
                description="Are you sure to delete?"
                onConfirm={handleClickDelete}
                okText="Yes"
                cancelText="No"
                okButtonProps={{ loading: loading, className: "submitBtn" }}
              >
                <Button loading={loading} type="primary" danger>
                  Delete
                </Button>
              </Popconfirm>
            </>
          ) : null}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUpdateUser;
