const getLabtestIdRecursively = (data) => {
  const selectedTestIds = [];

  data?.child?.map((item) => {
    selectedTestIds.push({
      testId: item?.id,
      // specimenId: item?.testDetails?.labTestSpecimens[0]?.id,
      // specimenExpirationInHours: item?.testDetails?.maxSpecimenAgeInHours,
      // specimen: item?.testDetails?.labTestSpecimens?.map((data) => data.name),
      // requiresBloodDraw:
      //   item?.testDetails?.labTestSpecimens[0]?.requiresBloodDraw,
      // requiresSampleOrigin:
      //   item?.testDetails?.labTestSpecimens[0]?.requiresSampleOrigin,
      // specimenVolReqData:
      //   item?.testDetails?.labTestSpecimens[0]?.testVolumeOrSizeRequirements,
    });
    if (item?.child && item?.child?.length > 0) {
      const childIds = getLabtestIdRecursively(item);
      selectedTestIds.push(...childIds);
    }
  });

  return selectedTestIds;
};

export default getLabtestIdRecursively;
