import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { URL_AUTH } from "config/connString";
import { userRefreshToken } from "api/auth/userRefreshToken";
import { BASE_URL } from "constants/connections";

const useAxios = () => {
  const authTokens = JSON.parse(localStorage.getItem("user"));
  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: { Authorization: `Bearer ${authTokens?.token}` },
  });

  axiosInstance.interceptors.request.use(async (req) => {
    const access = JSON.parse(localStorage.getItem("user"));
    const user = jwt_decode(access.token);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;

    try {
      const response = await axios.post(`${URL_AUTH}refresh-token`, {
        token: access.token,
        refreshToken: access.refreshToken,
      });

      localStorage.setItem("user", JSON.stringify(response.data.data));
      localStorage.setItem(
        "last-refresh-token",
        JSON.stringify({ dt: new Date().toLocaleString() })
      );

      req.headers.Authorization = `Bearer ${response.data.data.token}`;
      return req;
    } catch (error) {
      const getNewAccess = JSON.parse(localStorage.getItem("user"));
      if (error.response.status === 400) {
        req.headers.Authorization = `Bearer ${getNewAccess.token}`;
        return req;
      }
    }
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      let access = JSON.parse(localStorage.getItem("user"));

      if (access && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const res = await userRefreshToken();

        if (res.statusCode === 200) {
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${res.data.data.token}`;
          localStorage.setItem("user", JSON.stringify(res.data.data));
          localStorage.setItem(
            "last-refresh-token",
            JSON.stringify({ dt: new Date().toLocaleString() })
          );
          return axios(originalRequest);
        } else {
          localStorage.removeItem("user");
          localStorage.removeItem("last-refresh-token");
          localStorage.removeItem("claims");
        }
      }
      if (access && error.response.status === 400 && !originalRequest._retry) {
        originalRequest._retry = true;
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access.token}`;
        return axios(originalRequest);
      }

      return Promise.reject(error);
    }
  );
  return axiosInstance;
};

export default useAxios;
