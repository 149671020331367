import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  Zoom,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import decodedUserDetails from "utils/decodedUserDetails";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import moment from "moment";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Badge, Modal } from "antd";
import {
  checkPatientExistingOrder,
  getAllBarangay,
  getAllCityMunicipalities,
  getCountryLookUp,
  getPatientInfo,
  getPatientLookup,
  getPhilippineAddressTypes,
  getProvinceLookUp,
} from "store";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import { handleChangeFields } from "store/slice/inputSlice";
import { handleChangeLabtestData } from "store/slice/savedLabtestSlice";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import { handleChangeHandling } from "store/slice/specialHandlingSlice";
import notification from "components/jkt/global/openNotification";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import CreateUpdatePatientModal from "components/jkt/patient/createUpdatePatientModal";
import useTranslation from "hooks/useTranslation";

const SecondSection = () => {
  const [patientData, setPatientData] = useState([]);
  const [showCreateUpdatePatientModal, setShowCreateUpdatePatientModal] =
    useState(false);
  const filteredMedicalNoOptions = patientData.filter(
    (patient) =>
      patient.medicalRecordNo !== null && patient.medicalRecordNo !== ""
  );
  const sortedFilteredMedicalNoOptions = filteredMedicalNoOptions.sort((a, b) =>
    a?.medicalRecordNo?.localeCompare(b?.medicalRecordNo)
  );
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [cityMunicipalityOptions, setCityMunicipalityOptions] = useState([]);
  const [barangayOptions, setBarangayOptions] = useState([]);
  const [phAddressesTypeOptions, setPhAddressesTypeOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const savedInputs = useSelector((state) => state.savedInputs);
  const savedHandlingItems = useSelector((state) => state.specialHandling);
  const savedLabtests = useSelector((state) => state.savedLabtests);
  const inputs = useSelector((state) => state.inputs);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const clientId = decodedUserDetails()?.clientId;
  const dispatch = useDispatch();
  const filter = createFilterOptions();
  const isPatientForUpdate = multiStepFormInputs.patientId !== 0;
  const { getTranslation, getGlobalTranslation } =
    useTranslation("RequestPage");
  const hasClinicalDiagnosisData = multiStepFormInputs.diagnosesList.length > 0;
  const hasSpecialInstructionsData =
    savedHandlingItems?.savedHandling.length > 0;
  const hasPatientSpecimensData =
    multiStepFormInputs.patientSpecimens.length > 0;
  const hasLabtestData = savedLabtests.savedLabtestsData.length > 0;
  const isProviderIdFilled = multiStepFormInputs.providerId !== null;
  const showWarningNotification =
    isProviderIdFilled ||
    hasClinicalDiagnosisData ||
    hasSpecialInstructionsData ||
    hasPatientSpecimensData ||
    hasLabtestData;

  const notificationDescription = (
    <div>
      <p>
        {getTranslation(
          "By adding new patient the following fields will be cleared"
        )}
      </p>
      <div className="flex flex-col gap-1">
        {isProviderIdFilled && (
          <Badge status="error" text={getTranslation("Physician Name")} />
        )}
        {hasClinicalDiagnosisData && (
          <Badge status="error" text={getTranslation("Clinical Diagnosis")} />
        )}
        {hasSpecialInstructionsData && (
          <Badge status="error" text={getTranslation("Special Instructions")} />
        )}
        {hasLabtestData && (
          <Badge status="error" text={getTranslation("Laboratory Test")} />
        )}
        {hasPatientSpecimensData && (
          <Badge status="error" text={getTranslation("Specimen")} />
        )}
      </div>
    </div>
  );

  const handlePatientSearchChange = (e) => {
    //Search filter in patient's name dropdown input field
    dispatch(
      handleChangeSavedFields({
        searchPatientName: e.target.value,
      })
    );
  };
  const handleMedicalSearchChange = (e) => {
    //Search filter in patient's medical record no dropdown input field
    dispatch(
      handleChangeSavedFields({
        searchMedicalRecordNo: e.target.value,
      })
    );
  };

  const handleSelectPatient = (selectedPatientData) => {
    //Function for selecting a patient, and getting its id
    handleFetchPatientData(selectedPatientData?.id);
    dispatch(
      handleChangeSavedFields({
        searchPatientName: selectedPatientData?.displayName,
        searchMedicalRecordNo: selectedPatientData?.medicalRecordNo,
        selectedPatientId: selectedPatientData?.id,
        physicianName: "",
        isPatientNull: false,
      })
    );
    dispatch(
      handleChangeFields({
        dateOfBirth: moment(selectedPatientData?.dateOfBirth),
        fullPatientName: `${selectedPatientData?.firstname} ${
          selectedPatientData?.lastname === null
            ? ""
            : selectedPatientData?.lastname
        }`,
        medicalRecordNo: selectedPatientData?.medicalRecordNo,
      })
    );
    dispatch(
      handleChangeLabtestData({
        savedLabtestsData: [],
      })
    );
    dispatch(
      handleChangeMultiFields({
        patientId: selectedPatientData.id,
        providerId: null,
        diagnosesList: [],
        clientWillDrawSpecimen: true,
        specialHandlingIds: [],
        testOrderDetails: [],
        patientSpecimens: [],
      })
    );
    dispatch(
      handleChangeHandling({
        savedHandling: [],
      })
    );
    notification.success({
      message: getGlobalTranslation("Added Patient"),
      description: `${selectedPatientData.displayName} ${getTranslation(
        "selected successfully"
      )}`,
    });

    if (showWarningNotification) {
      setTimeout(() => {
        notification.warning({
          message: getGlobalTranslation("Cleared Input Fields"),
          description: notificationDescription,
        });
      }, 1000);
    }
  };

  const handleOpenModal = () => {
    //Function for opening the create or update patient modal
    if (isPatientForUpdate) {
      handleFetchPatientData(multiStepFormInputs.patientId);
      setShowCreateUpdatePatientModal(true);
    } else {
      setShowCreateUpdatePatientModal(true);
      dispatch(
        handleChangeSavedFields({
          searchPatientName: "",
          selectedPatientId: null,
        })
      );
    }
  };

  const handleCloseModal = () => {
    //For closing of patient's form modal whether it is for create or update
    setShowCreateUpdatePatientModal(false);
  };

  const handleFetchPatientLookup = () => {
    //Fetching of patient lookup that will be displayed in dropdown search input field. Both in patient name and medical record no
    dispatch(
      getPatientLookup({
        clientId: clientId,
      })
    ).then((response) => {
      if (response.type === "patients/get-patient-lookup/fulfilled") {
        setPatientData(response.payload.data.items);
      } //Getting the response of the get specific patient action
    });
  };

  const handleFetchPatientData = (patientId) => {
    //Fetching of patients information for update
    handleCheckPatientExistingOrder(patientId);

    dispatch(
      getPatientInfo({
        id: patientId,
      }) //Dispatching of get patient info action
    ).then((response) => {
      //Getting of the response from that action
      const responseData = response?.payload?.data;
      const isFetchSuccess = response?.payload?.success;
      const isFetchFailed = !response?.payload?.success;
      if (isFetchSuccess) {
        const phAddressesData = responseData?.phAddresses.map((address) => ({
          id: address?.id,
          type: address?.type,
          barangayId: address?.barangayId === 0 ? null : address?.barangayId,
          cityMunicipalityId:
            address.cityMunicipalityId === 0
              ? null
              : address?.cityMunicipalityId,
          provinceId: address?.provinceId === 0 ? null : address?.provinceId,
          houseBuildingNumber: address?.houseBuildingNumber,
          streetName: address?.streetName,
          postalCode: address?.postalCode,
          country: address?.country,
        })); //Checking beforehand if all of the nullable items are being set to null
        dispatch(
          handleChangeFields({
            id: responseData?.id,
            isActive: responseData?.isActive,
            fullPatientName: `${responseData?.firstname} ${
              responseData?.lastname === null ? "" : responseData?.lastname
            }`,
            gender: responseData?.gender,
            dateOfBirth: moment(responseData?.dateOfBirth),
            contactNumber:
              responseData?.contactNumber === ""
                ? null
                : responseData?.contactNumber,
            emails: responseData?.emails,
            patientEmailForDisplay: responseData?.emails,
            client_ID: responseData?.client_ID,
            medicalRecordNo:
              responseData?.medicalRecordNo === ""
                ? null
                : responseData?.medicalRecordNo,
            cardNumber: responseData?.cardNumber,
            governmentId:
              responseData?.governmentId === ""
                ? null
                : responseData?.governmentId,
            phAddresses: phAddressesData,
          })
        );
      }
      if (isFetchFailed) {
        //Checking if the request didn't pass through, then display an error toast notification
        notification.error({
          message: "Failed to fetch patient data",
          description: errorMessagesDescription(
            response?.payload?.response?.data?.errorMessages
          ),
        });
      }
    });
  };

  const handleCheckPatientExistingOrder = (patientId) => {
    //Check if the patient has test order that have already accepted by ABC Laboratory
    if (
      checkPatientExistingOrder.pending().type ===
      "patients/check-existing-order/pending"
    ) {
      setIsLoading(true); //Check if the fetching is being processed
    }
    dispatch(
      checkPatientExistingOrder({
        patientId: patientId,
      })
    ).then((res) => {
      const isFetchSuccess = res?.payload?.success;
      if (isFetchSuccess) {
        dispatch(
          handleChangeFields({
            isPatientHasAnAcceptedOrder: res?.payload?.data,
          })
        );
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    handleFetchPatientLookup(); //Invoking of the get function
  }, [savedInputs.refetchData]);

  useEffect(() => {
    //Checking if the patient name's dropdown search input is cleared, then empty the other input fields that uses that patient
    if (savedInputs.searchPatientName === "") {
      dispatch(
        handleChangeSavedFields({
          selectedPatientId: null,
          searchMedicalRecordNo: "",
        })
      );
      dispatch(
        handleChangeMultiFields({
          patientId: 0,
        })
      );
      dispatch(
        handleChangeFields({
          id: 0,
          fullPatientName: "",
          isActive: true,
          gender: "",
          dateOfBirth: "" || null,
          contactNumber: "",
          emails: [],
          medicalRecordNo: "",
          cardNumber: "",
          governmentId: "",
          phAddresses: [],
          isPatientHasAnAcceptedOrder: false,
          patientEmailForDisplay: [],
        })
      );
    }
  }, [dispatch, savedInputs.searchPatientName]);

  useEffect(() => {
    const config = {
      search: "",
      pageSize: 0,
      pageNumber: 1,
      sortField: "Id",
      sortDirection: 0,
    };
    dispatch(getProvinceLookUp()).then((response) => {
      //Fetching of province options
      setProvinceOptions(response?.payload?.data);
    });
    dispatch(getAllCityMunicipalities(config)).then((response) => {
      //Fetching of city municipality options
      setCityMunicipalityOptions(response?.payload?.data?.items);
    });
    dispatch(getAllBarangay(config)).then((response) => {
      //Fetching of barangay options
      setBarangayOptions(response?.payload?.data?.items);
    });
    dispatch(getPhilippineAddressTypes()).then((response) => {
      //Fetching of address type options
      setPhAddressesTypeOptions(response?.payload?.data);
    });
    dispatch(getCountryLookUp()).then((response) => {
      //Fetching of country options
      setCountryOptions(response?.payload?.data);
    });
  }, [dispatch]);

  return (
    <Grid rowSpacing={2} columnSpacing={4} sx={{ marginTop: 3 }} container>
      <Grid
        sx={{ position: "relative" }}
        xs={12}
        sm={12}
        md={8}
        lg={8}
        xl={8}
        item
      >
        <Typography
          variant="p"
          sx={{ fontWeight: "bold", color: "darkBlue.main" }}
          className="required"
        >
          {getTranslation("Patient name")}
        </Typography>
        <br />
        <Box
          sx={{
            display: "flex",
            gap: "0.3rem",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Autocomplete
            disableClearable
            disablePortal
            clearOnBlur
            size="small"
            value={savedInputs.searchPatientName}
            options={patientData}
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "30rem",
                lg: "30rem",
                xl: "30rem",
              },
            }}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setTimeout(() => {
                  handleOpenModal();
                });
              } else if (newValue && newValue.inputValue) {
                handleOpenModal();
              } else {
                handleSelectPatient(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params.inputValue !== "") {
                filtered.push({
                  inputValue: params.inputValue,
                  displayNameWithDOB: `${getTranslation("Add new patient")}*`,
                });
              }
              return filtered;
            }}
            getOptionDisabled={(option) =>
              multiStepFormInputs.patientId === option.id
            }
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              if (option.displayName) {
                return option.displayName;
              }
              if (option.displayNameWithDOB) {
                return option.displayNameWithDOB;
              }
            }}
            renderOption={(props, patient) => (
              <>
                <li
                  style={{
                    pointerEvents:
                      multiStepFormInputs.patientId === patient.id
                        ? "none"
                        : "auto",
                    margin: "0.2rem",
                    backgroundColor:
                      patient.displayNameWithDOB.includes("Add new") ||
                      patient.displayNameWithDOB.includes("Tambah pasien")
                        ? "#c1dbff"
                        : "",
                  }}
                  {...props}
                >
                  {patient.displayNameWithDOB}
                </li>
              </>
            )}
            renderInput={(params) => (
              <TextField
                error={savedInputs.isPatientNull}
                value={savedInputs.searchPatientName}
                size="small"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "30rem",
                    lg: "30rem",
                    xl: "30rem",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                      border: "2px solid",
                      borderColor: "darkBlue.secondary",
                    },
                  },
                  borderRadius: "5px",
                  outline: "none",
                  marginTop: "0.5rem",
                }}
                onChange={handlePatientSearchChange}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      <Box sx={{ overflow: "hidden", opacity: 0 }}>
                        {params.InputProps.endAdornment}
                      </Box>
                      <InputAdornment
                        sx={{ transform: "translateX(30px)" }}
                        position="end"
                      >
                        <SearchOutlinedIcon
                          sx={{
                            color: "lightBlue.main",
                            pointerEvents: "none",
                          }}
                        />
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
          />
          <Tooltip
            title={
              multiStepFormInputs.patientId === 0
                ? getTranslation("Add new patient")
                : getTranslation("Edit patient")
            }
            placement="right-end"
            TransitionComponent={Zoom}
            arrow
          >
            <Box
              sx={{
                backgroundColor: "darkBlue.main",
                padding: "0.7rem",
                height: "2.55rem",
                borderRadius: "0.3rem",
                cursor: "pointer",
                marginTop: "0.5rem",
                transition: "0.3s ease-in-out",
                ":hover": {
                  backgroundColor: "darkBlue.secondary",
                },
              }}
              onClick={handleOpenModal}
            >
              {!isPatientForUpdate ? (
                <PersonAddOutlinedIcon
                  sx={{
                    fontSize: "20px",
                    marginBottom: "0.1rem",
                    color: "white",
                    transform: "translateY(-3px)",
                  }}
                />
              ) : (
                <EditOutlinedIcon
                  sx={{
                    fontSize: "20px",
                    marginBottom: "0.3rem",
                    color: "white",
                    transform: "translateY(-3px)",
                  }}
                />
              )}
            </Box>
          </Tooltip>
        </Box>
      </Grid>
      <Grid xs={12} sm={12} md={4} lg={4} xl={4} item>
        <Typography
          color="primary"
          variant="p"
          sx={{ fontWeight: "bold", color: "darkBlue.main" }}
        >
          {getTranslation("Medical record no")}
        </Typography>
        <br />
        <Autocomplete
          disableClearable
          clearOnBlur
          size="small"
          value={savedInputs.searchMedicalRecordNo}
          options={sortedFilteredMedicalNoOptions}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              setTimeout(() => {
                handleOpenModal();
              });
            } else if (newValue && newValue.inputValue) {
              handleOpenModal();
            } else {
              handleSelectPatient(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== "") {
              filtered.push({
                inputValue: params.inputValue,
                medicalRecordNo: `${getTranslation("Add new patient")}*`,
              });
            }
            return filtered;
          }}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            if (option.medicalRecordNo) {
              return option.medicalRecordNo;
            }
          }}
          renderOption={(props, patient) => (
            <>
              <li
                style={{
                  margin: "0.2rem",
                  backgroundColor:
                    patient.medicalRecordNo.includes("Add new") ||
                    patient.medicalRecordNo.includes("Tambah pasien")
                      ? "#c1dbff"
                      : "",
                }}
                {...props}
              >
                {patient?.medicalRecordNo?.length > 0 &&
                  patient?.medicalRecordNo}
              </li>
            </>
          )}
          renderInput={(params) => (
            <TextField
              value={inputs.searchMedicalRecordNo}
              size="small"
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "17.5rem",
                  lg: "17.5rem",
                  xl: "17.5rem",
                },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.secondary",
                  },
                },
                borderRadius: "5px",
                outline: "none",
                marginTop: "0.5rem",
              }}
              onChange={handleMedicalSearchChange}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <Box sx={{ overflow: "hidden", opacity: 0 }}>
                      {params.InputProps.endAdornment}
                    </Box>
                    <InputAdornment
                      sx={{ transform: "translateX(30px)" }}
                      position="end"
                    >
                      <SearchOutlinedIcon
                        sx={{
                          color: "lightBlue.main",
                          pointerEvents: "none",
                        }}
                      />
                    </InputAdornment>
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Modal
        closable
        open={showCreateUpdatePatientModal}
        width={900}
        footer={null}
        onCancel={handleCloseModal}
      >
        <CreateUpdatePatientModal
          countryOptions={countryOptions}
          phAddressesTypeOptions={phAddressesTypeOptions}
          provinceOptions={provinceOptions}
          cityMunicipalityOptions={cityMunicipalityOptions}
          barangayOptions={barangayOptions}
          isPatientDataLoading={isLoading}
          handleCloseModal={handleCloseModal}
          handleFetchPatientData={handleFetchPatientData}
        />
      </Modal>
    </Grid>
  );
};

export default SecondSection;
