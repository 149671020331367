import moment from "moment";

const getSpecimenOptionsRecursively = (data) => {
  const selectedSpecimenOptions = [];
  data?.child?.map((item) => {
    if (item?.testDetails?.labTestSpecimens.length > 1) {
      item?.testDetails?.labTestSpecimens.map((specimenData) => {
        selectedSpecimenOptions.push({
          labTestInfoId: item?.testDetails?.labTestId,
          requiresSampleOrigin: specimenData?.requiresSampleOrigin,
          requiresBloodDraw: specimenData?.requiresBloodDraw,
          specimen: specimenData?.name,
          key: specimenData?.name,
          specimenExpirationInHours: item?.testDetails?.maxSpecimenAgeInHours,
          unitOfMeasure: specimenData?.unitOfMeasure,
          specimenId: specimenData?.id,
          selectedCollectionSiteOption: "",
          specimenQuantity:
            specimenData.testVolumeOrSizeRequirements &&
            specimenData.testVolumeOrSizeRequirements.find((data) =>
              Math.min(data.minTest)
            )?.minVolume + "",
          specimenVolReqData: specimenData.testVolumeOrSizeRequirements,
          collectionDateTime: moment().format(),
          collectorId: specimenData?.requiresBloodDraw ? "" : null,
          collectionSite: specimenData?.requiresSampleOrigin ? "" : null,
          status: 0,
        });
      });
    }
    if (item?.child && item?.child?.length > 0) {
      const childSpecimens = getSpecimenOptionsRecursively(item);
      selectedSpecimenOptions.push(...childSpecimens);
    }
  });

  return selectedSpecimenOptions;
};

export default getSpecimenOptionsRecursively;
