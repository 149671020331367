import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_SAMPLE_COLLECTORS } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import decodedUserDetails from "utils/decodedUserDetails";

const createSampleCollector = createAsyncThunk(
  "sample-collectors/create-sample-collector",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();

      const response = await api.post(URL_SAMPLE_COLLECTORS, {
        licenseNumber: null,
        licenseExpirationDate: null,
        firstName: data.firstName,
        lastName: data.lastName,
        collectorType: data.collectorType,
        clientId: clientId,
        phoneNumber: null,
        email: null,
        isActive: true,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createSampleCollector };
