import { ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { appBarItems } from "../../../data/appBarItems";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslation from "hooks/useTranslation";
import { handleChangeFields } from "store/slice/inputSlice";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";

const GroupedAppbarItemsButton = ({
  expandItem,
  onExpandSidebarItems,
  icon,
  isSidebarOpen,
  tooltipTitle,
}) => {
  const dispatch = useDispatch();
  const { claims } = useSelector((state) => state.auth);
  const { getTranslation } = useTranslation("SidebarItems");
  const selectedParentCollapseKey = localStorage.getItem(
    "selectedParentCollapseKey"
  );
  const location = useLocation();
  const navigate = useNavigate();
  const { expandAdminSidebarItems, expandLaboratorySidebarItems } = useSelector(
    (state) => state.appBarSettings
  );
  const elementRenderSequence = Object.keys(appBarItems);
  const elementClaims = elementRenderSequence?.map((key) =>
    claims?.find((element) => element?.permissionKey === key)
  );

  const splittedPermissionKey = (permissionKey) => {
    return permissionKey?.split(":");
  };

  const getRoutesDataByPermission = (permissionKey) => {
    const selectedPermission = appBarItems[permissionKey];

    return {
      routeName: selectedPermission.routeName,
      routeLink: selectedPermission.routeLink,
      routeIcon: selectedPermission.routeIcon,
    };
  };

  const handleClearPatientInputFields = () => {
    //For clearing patient's input data
    dispatch(
      handleChangeFields({
        id: 0,
        fullPatientName: "",
        gender: "",
        dateOfBirth: null,
        contactNumber: "",
        emails: [],
        patientEmailForDisplay: [],
        medicalRecordNo: "",
        cardNumber: "",
        governmentId: "",
        phAddresses: [],
        isPatientHasAnAcceptedOrder: false,
      })
    );
    dispatch(
      handleChangeMultiFields({
        patientId: 0,
      })
    );
  };

  const handleNavigate = (e, routeLink) => {
    e.preventDefault();
    navigate(routeLink);
    if (routeLink === "/patient") {
      handleClearPatientInputFields();
    }
  };

  const renderTranslatedRouteName = (routeName) => {
    const translatedRouteName = getTranslation(routeName);

    if (translatedRouteName === undefined) {
      return routeName;
    }

    return translatedRouteName;
  };

  const parentCollapseKeyToBeSelected = () => {
    if (
      selectedParentCollapseKey === "Laboratory" &&
      !expandLaboratorySidebarItems
    ) {
      return getTranslation("Laboratory");
    } else if (
      selectedParentCollapseKey === "Administration" &&
      !expandAdminSidebarItems
    ) {
      return getTranslation("Administration");
    }
  };

  return (
    <div>
      <Tooltip
        arrow
        placement="right"
        title={!isSidebarOpen ? tooltipTitle : ""}
      >
        <ListItemButton
          selected={parentCollapseKeyToBeSelected() === tooltipTitle}
          onClick={() => dispatch(onExpandSidebarItems())}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={tooltipTitle} />
          <ExpandMore
            sx={{
              transform: expandItem ? "rotate(180deg)" : "rotate(0)",
              transition: "0.3s ease-in-out",
            }}
          />
        </ListItemButton>
      </Tooltip>
      <Collapse in={expandItem} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {elementClaims
            ?.filter(
              (data) =>
                getTranslation(
                  splittedPermissionKey(data?.permissionKey)[0]
                ) === tooltipTitle
            )
            ?.map((element, index) => {
              const key = splittedPermissionKey(element?.permissionKey);
              if (
                (key[2] === "Modify" || key[2] === "Read") &&
                element?.isGranted
              ) {
                const routeName = getRoutesDataByPermission(
                  element?.permissionKey
                )?.routeName;
                const translatedRouteName =
                  renderTranslatedRouteName(routeName);

                return (
                  <Tooltip
                    arrow
                    placement="right"
                    key={index}
                    title={!isSidebarOpen ? translatedRouteName : ""}
                  >
                    <ListItemButton
                      sx={{ width: "100%" }}
                      onClick={(e) => {
                        handleNavigate(
                          e,
                          getRoutesDataByPermission(element?.permissionKey)
                            ?.routeLink
                        );
                        localStorage.setItem(
                          "selectedParentCollapseKey",
                          key[0]
                        );
                      }}
                      selected={
                        getRoutesDataByPermission(element?.permissionKey)
                          ?.routeLink === location.pathname
                      }
                    >
                      <ListItemIcon
                        sx={{
                          pl: isSidebarOpen ? 1 : 0,
                          transition: "0.3s ease-in-out",
                        }}
                      >
                        {
                          getRoutesDataByPermission(element?.permissionKey)
                            ?.routeIcon
                        }
                      </ListItemIcon>
                      {isSidebarOpen && (
                        <ListItemText
                          sx={{ whiteSpace: "normal" }}
                          primary={translatedRouteName}
                        />
                      )}
                    </ListItemButton>
                  </Tooltip>
                );
              }
            })}
        </List>
      </Collapse>
    </div>
  );
};

export default GroupedAppbarItemsButton;
