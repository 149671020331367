import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import RequestPageSidePanel from "../../sidePanel/requestPageSidePanel";
import { useDispatch, useSelector } from "react-redux";
import {
  createTestOrder,
  emailPatientTestOrder,
  getUserInfoById,
  updateTestOrder,
  uploadFile,
} from "store";
import styles from "./styles.module.css";
import { useReactToPrint } from "react-to-print";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import RegularVolumeCheckerModal from "../secondStep/regularVolumeCheckerModal";
import { FirstStep } from "../firstStep/firstStep";
import { SecondStep } from "../secondStep/secondStep";
import { ThirdStep } from "../thirdStep/thirdStep";
import { FourthStep } from "../fourthStep/fourthStep";
import DuplicatedTestOrderWarningModal from "./duplicatedTestOrderWarningModal";
import { useMultistepForm } from "hooks/useMultistepForm";
import useClearReduxStore from "hooks/useClearReduxStore";
import { handleChangeOrderData } from "store/slice/orderSlice";
import notification from "components/jkt/global/openNotification";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import LoadingSpinner from "components/jkt/global/loadingSpinner";
import ResultComponent from "components/jkt/global/resultComponent";
import useTranslation from "hooks/useTranslation";
import VolumeCheckerModal from "../secondStep/volumeCheckerModal";
import { parsedClientDetails } from "utils/parsedClientDetails";
import { encryptTestOrder } from "store";
import { isDataEmpty } from "utils/isDataEmpty";

const FormContent = () => {
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const orderData = useSelector((state) => state.orderData);
  const savedInputs = useSelector((state) => state.savedInputs);
  const inputs = useSelector((state) => state.inputs);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadPending, setIsDownloadPending] = useState(false);
  const { handleResetReduxStore } = useClearReduxStore();
  const [orderNumber, setOrderNumber] = useState(null);
  const componentRef = useRef();
  const promiseResolveRef = useRef();
  const [printTestOrder, setPrintTestOrder] = useState(false);
  const dispatch = useDispatch();
  const { getTranslation } = useTranslation("RequestPage");
  const { clientDetails, enableVolumeCheck, enableCollectionSite } =
    parsedClientDetails();
  const { getTranslation: getResultModalTranslation } = useTranslation(
    "SuccessfulOrderModal"
  );
  const [showDuplicatedTestOrderModal, setShowDuplicatedTestOrderModal] =
    useState(false);
  const hasDuplicatedTestOrder = orderData.duplicatedTestOrderData.length > 0;
  const testCreatorId = orderData.duplicatedTestOrderData[0]?.createdBy;
  const [duplicatedTestCreator, setDuplicatedTestCreator] = useState("");

  useEffect(() => {
    if (printTestOrder && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [printTestOrder]);

  const onBeforePrintContent = () => {
    //Before printing the test order summary, it should display all of the data not the paginated one using a state
    return new Promise((resolve) => {
      promiseResolveRef.current = resolve;
      setPrintTestOrder(true);
    });
  };

  const onAfterPrintContent = () => {
    //After printing set the state to false, to re display the paginated data
    return new Promise((resolve) => {
      promiseResolveRef.current = resolve;
      setPrintTestOrder(false);
    });
  };

  useEffect(() => {
    if (printTestOrder && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [printTestOrder]);

  const {
    handleNext,
    handlePrev,
    step,
    isLastStep,
    isFirstStep,
    formHeaderText,
    steps,
    progressText,
    currentStepIndex,
    setCurrentStepIndex,
    savedInvalidText,
  } = useMultistepForm([
    <FirstStep />,
    <SecondStep />,
    <ThirdStep />,
    <FourthStep
      id="content"
      ref={componentRef}
      printTestOrder={printTestOrder}
    />, //Getting of data from a custom hook which is to render a multistep form
  ]);

  useEffect(() => {
    //For fetching of duplicated test order creator name
    if (hasDuplicatedTestOrder) {
      dispatch(getUserInfoById(testCreatorId)).then((res) => {
        const isFetchSuccessful = res?.payload?.success;
        if (isFetchSuccessful) {
          setDuplicatedTestCreator(res?.payload?.data?.displayName);
        }
      });
    }
  }, [dispatch, testCreatorId, hasDuplicatedTestOrder]);

  const handleCreateTestOrder = (e) => {
    //For creating new test order
    e.preventDefault();
    if (orderData.isOrderDataForUpdate) {
      //Check if the order is for update
      handleUpdateTestOrder();
    } else {
      const formattedPatientSpecimens =
        multiStepFormInputs.patientSpecimens.map(
          ({
            specimen,
            requiresBloodDraw,
            requiresSampleOrigin,
            unitOfMeasure,
            specimenExpirationInHours,
            specimenVolReqData,
            selectedCollectionSiteOption,
            ...rest
          }) => rest
        ); //Formatted test order which remove the data that is not needed when submitting a patient specimens
      const checkedPatientSpecimensCollectionSite =
        formattedPatientSpecimens?.map((item) => ({
          ...item,
          collectionSite: enableCollectionSite ? item.collectionSite : null,
        }));

      const formattedTestOrderDetails =
        multiStepFormInputs.testOrderDetails?.map(
          ({ testIds, duplicatedSpecimenKeys, id, testOrderId, ...rest }) =>
            rest
        ); //Formatted test order which remove the data that is not needed when submitting a test order

      const filteredFormattedTestOrderDetails = formattedTestOrderDetails?.map(
        (order) => ({
          ...order,
          patientTestRequirementDatas: order?.patientTestRequirementDatas?.map(
            (item) => ({
              ...item,
              patientRequirementDataDetails:
                item?.patientRequirementDataDetails?.filter(
                  (data) => !isDataEmpty(data.dataValue)
                ),
            })
          ),
        })
      ); // Filter test order details with additional patient information that has no data value

      if (
        createTestOrder.pending().type ===
        "test-orders/create-test-order/pending"
      ) {
        setIsLoading(true); //Check if create action is being processed
      }
      dispatch(
        createTestOrder({
          clientWillDrawSpecimen: multiStepFormInputs.clientWillDrawSpecimen,
          patientId: multiStepFormInputs.patientId,
          clientId: multiStepFormInputs.clientId,
          providerId: multiStepFormInputs.providerId,
          diagnosesList: multiStepFormInputs.diagnosesList,
          specialHandlingIds: multiStepFormInputs.specialHandlingIds,
          testOrderDetails: filteredFormattedTestOrderDetails,
          patientSpecimens: multiStepFormInputs.clientWillDrawSpecimen
            ? checkedPatientSpecimensCollectionSite
            : [],
        })
      ).then((res) => {
        //Dispatching of create test order provided the data that is needed
        if (res.payload.success) {
          //Check if the request succeed if it is, then get its order number
          //Encrypt created test order
          dispatch(
            encryptTestOrder({
              orderId: res?.payload?.data?.id,
            })
          ).then((encryptionResponse) => {
            setOrderNumber(res?.payload?.data?.autoGeneratedOrderNumber);
            setIsLoading(false);
            dispatch(
              handleChangeOrderData({
                successfullyCreatedOrder: true,
                autoGeneratedOrderNumber:
                  res?.payload?.data?.autoGeneratedOrderNumber,
                duplicatedTestOrderData: [],
                orderStatus: res?.payload?.data?.orderStatus,
                encryptedTestOrderData: encryptionResponse?.payload?.data,
              })
            );
            setShowDuplicatedTestOrderModal(false);
          });
        }
        if (!res.payload.success) {
          //Check if the request fails, if it is then display an error toast notification
          setIsLoading(false);
          dispatch(
            handleChangeOrderData({
              successfullyCreatedOrder: false,
            })
          );
          notification.error({
            message: "Failed to Create Order",
            description: errorMessagesDescription(
              res.payload?.response.data.errorMessages
            ),
          });
        }
      });
    }
  };

  const handleUpdateTestOrder = () => {
    //Update test order functionality
    const formattedTestOrderDetails = multiStepFormInputs.testOrderDetails.map(
      ({ testIds, duplicatedSpecimenKeys, ...rest }) => rest
    ); //Formatted test order which remove the data that is not needed when submitting a patient specimens

    const formattedPatientSpecimens = multiStepFormInputs.patientSpecimens.map(
      ({
        specimen,
        requiresBloodDraw,
        requiresSampleOrigin,
        unitOfMeasure,
        specimenExpirationInHours,
        specimenVolReqData,
        selectedCollectionSiteOption,
        ...rest
      }) => rest
    ); //Formatted test order which remove the data that is not needed when submitting a patient specimens

    if (
      updateTestOrder.pending().type === "test-orders/update-test-order/pending"
    ) {
      setIsLoading(true); //Check if update action is being processed
    }
    dispatch(
      updateTestOrder({
        clientWillDrawSpecimen: multiStepFormInputs.clientWillDrawSpecimen,
        id: multiStepFormInputs.id,
        clientId: multiStepFormInputs.clientId,
        patientId: multiStepFormInputs.patientId,
        providerId: multiStepFormInputs.providerId,
        diagnosesList: multiStepFormInputs.diagnosesList,
        specialHandlingIds: multiStepFormInputs.specialHandlingIds,
        testOrderDetails: formattedTestOrderDetails,
        patientSpecimens: multiStepFormInputs.clientWillDrawSpecimen
          ? formattedPatientSpecimens
          : [],
      })
    ).then((res) => {
      //Dispatching of update test order provided the data that is needed
      if (res.payload.success) {
        //Check if the request succeed if it is, then get its order number
        dispatch(
          encryptTestOrder({
            orderId: res?.payload?.data?.id,
          })
        ).then((encryptionResponse) => {
          setOrderNumber(res?.payload?.data?.autoGeneratedOrderNumber);
          setIsLoading(false);
          dispatch(
            handleChangeOrderData({
              successfullyCreatedOrder: true,
              autoGeneratedOrderNumber:
                res?.payload?.data?.autoGeneratedOrderNumber,
              duplicatedTestOrderData: [],
              orderStatus: res?.payload?.data?.orderStatus,
              encryptedTestOrderData: encryptionResponse?.payload?.data,
            })
          );
          setShowDuplicatedTestOrderModal(false);
        });
      }
      if (!res.payload.success) {
        //Check if the request fails, if it is then display an error toast notification
        setIsLoading(false);
        dispatch(
          handleChangeOrderData({
            successfullyCreatedOrder: false,
          })
        );
        notification.error({
          message: "Failed to Update Order",
          description: errorMessagesDescription(
            res.payload?.response.data.errorMessages
          ),
        });
      }
    });
  };

  const convertElementToPdf = async () => {
    window.scrollTo(0, 0); //Automatically scroll at the very top, to avoid unclear content
    const elementToPrint = document.getElementById("content"); //Get the id of the element that will be converted to pdf
    const canvas = await html2canvas(elementToPrint); //Convert element to canvas

    const pdf = new jsPDF({
      //Create a new jsPDF constructor to use its methods
      orientation: "portrait",
      unit: "mm",
      compress: true,
    });

    const imgData = canvas.toDataURL("image/png"); //Convert canvas to base64
    const imgWidth = 190; //Image width
    const pageHeight = 290; //Page height
    const imgHeight = (canvas.height * imgWidth) / canvas.width; //Image height
    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "PNG", 10, 0, imgWidth, imgHeight + 10); //Set the height of the image
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      //Condition so that the image will properly fit in document
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight + 25);
      heightLeft -= pageHeight;
    }
    return pdf;
  };

  const handleConvertTestOrderToPdf = async (e) => {
    //Function to convert a react component to a pdf
    e.preventDefault();
    setIsDownloadPending(true); //Check if the conversion is processing, then display spinner
    const pdf = await convertElementToPdf(); //Getting of generated pdf
    try {
      pdf.save(
        `${orderData?.autoGeneratedOrderNumber}_${moment().format("LLL")}`
      );
      setIsDownloadPending(false);
      notification.success({
        message: "Converted Test Order Data to PDF",
        description: "Successfully downloaded test order data to pdf",
      });
    } catch (err) {
      setIsDownloadPending(false);
    }
  };

  const handleEmailTestOrderToPatient = async (e) => {
    e.preventDefault();
    setIsDownloadPending(true); //Set the loading state to true, for showing loading spinner

    try {
      const pdf = await convertElementToPdf(); //Getting of generated pdf
      const pdfBlob = pdf.output("blob"); //Convert image to pdf
      const file = new File( //File constructor for setting the file that will be uploaded
        [pdfBlob],
        `${inputs.fullPatientName}-${moment().format("L")}.pdf`,
        { type: "application/pdf" }
      );
      dispatch(uploadFile({ file })) //Dispatch upload file action
        .then((response) => {
          dispatch(
            emailPatientTestOrder({
              patientId: multiStepFormInputs.patientId,
              filePath: response?.payload[0]?.tempPath,
            }) //Dispatch email patient test order action
          ).then((res) => {
            notification.success({
              message: "Emailed Test Order Summary",
              description: (
                <p>
                  Successfully emailed test order summary to{" "}
                  <strong>{inputs.fullPatientName}</strong>
                </p>
              ),
            });

            setIsDownloadPending(false);
          });
        })
        .catch(() => {
          setIsDownloadPending(false);
          notification.error({
            message: "Error",
            description:
              "Failed to email test order summary, please try again.",
          });
        });
    } catch (err) {
      setIsDownloadPending(false);
    }
  };

  const handleShowDuplicatedTestOrderModal = () => {
    setShowDuplicatedTestOrderModal(true);
  };

  const handleClearTestOrderInputs = () => {
    //Function for clearing all of test order data that is inputted by the user
    setShowDuplicatedTestOrderModal(false);
    handleResetReduxStore();
    localStorage.removeItem("searchCollector");
    setCurrentStepIndex(0);
    if (showDuplicatedTestOrderModal) {
      notification.success({
        message: "Cancelled Test Order",
        description: "Successfully cancelled test order",
      });
    }
  };

  const handleCloseDuplicatedTestOrderModal = () => {
    setShowDuplicatedTestOrderModal(false);
  };

  useEffect(() => {
    handleResetReduxStore();
    localStorage.removeItem("searchCollector");
  }, []);

  const handlePrintOrder = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: onBeforePrintContent,
    onAfterPrint: onAfterPrintContent,
  });

  const buttons = [
    //Result notification popup buttons
    <Button
      style={{ textTransform: "none" }}
      onClick={handlePrintOrder}
      type="button"
      endIcon={<PrintOutlinedIcon />}
      variant="contained"
      sx={{
        backgroundColor: "#86516B",
        ":hover": {
          backgroundColor: "#865161",
        },
      }}
    >
      {getResultModalTranslation("Print order")}
    </Button>, //For printing the orders data
    <Button
      style={{ textTransform: "none" }}
      type="button"
      endIcon={<PictureAsPdfIcon />}
      onClick={handleConvertTestOrderToPdf}
      variant="contained"
      color="error"
    >
      {getResultModalTranslation("Export to PDF")}
    </Button>, // Converting test order data to pdf
    <Button
      style={{ textTransform: "none" }}
      endIcon={<AddShoppingCartOutlinedIcon />}
      onClick={handleClearTestOrderInputs}
      variant="contained"
      color="success"
    >
      {getResultModalTranslation("Create new order")}
    </Button>, //Button for for creating new order, all form will be cleared
    <Button
      style={{
        textTransform: "none",
        display:
          clientDetails?.clientConfig?.allowEmailSendingOfRequest &&
          inputs?.patientEmailForDisplay?.length > 0
            ? "initial"
            : "none",
      }}
      onClick={handleEmailTestOrderToPatient}
      sx={{ marginTop: savedInputs.selectedLanguage === "IN" ? "1rem" : 0 }}
      endIcon={<MailOutlineIcon />}
      variant="contained"
      color="primary"
      disabled={inputs.emails.length === 0}
    >
      {getResultModalTranslation("Email to patient")}
    </Button>, //Button for sending test order details to patient through email
  ];

  return (
    <Grid
      component="form"
      sx={{
        display: "flex",
        flexWrap: "wrap-reverse",
        minHeight: "100vh",
        height: "100%",
        marginBottom: "6rem",
      }}
      onSubmit={handleCreateTestOrder}
      container
    >
      <Grid xs={12} sm={12} md={12} lg={12} xl={9} item>
        <Typography
          sx={{
            color: "darkBlue.main",
            margin: "1.6rem auto",
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
              xl: "60rem",
            },
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "block",
            },
            fontSize: "2rem",
            fontWeight: "600",
          }}
          variant="h4"
        >
          {getTranslation(formHeaderText)}
        </Typography>
        <Box
          sx={{
            background: "white",
            margin: {
              xs: "0 auto",
              sm: "0 auto",
              md: "0 auto",
              lg: "0 auto",
              xl: "1rem auto",
            },
            borderRadius: { xs: 0, sm: 0, md: 0, lg: 0, xl: "1rem" },
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
              xl: "60rem",
            },
          }}
        >
          <Typography
            sx={{
              color: "darkBlue.main",
              padding: "2rem",
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "60rem",
              },
              display: {
                xs: "block",
                sm: "block",
                md: "block",
                lg: "block",
                xl: "none",
              },
              textAlign: "center",
            }}
            variant="h5"
          >
            {formHeaderText}
          </Typography>
          {step}
        </Box>
        {isLastStep && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "1rem 0",
              marginBottom: { xs: 7, sm: 7, md: 7, lg: 7, xl: 0 },
            }}
          >
            {!hasDuplicatedTestOrder && (
              <button type="submit" className={styles["submit-btn"]}>
                {orderData.isOrderDataForUpdate
                  ? getTranslation("Update Order")
                  : getTranslation("Submit Order")}
              </button>
            )}
            {hasDuplicatedTestOrder && (
              <button
                onClick={handleShowDuplicatedTestOrderModal}
                type="button"
                className={styles["submit-btn"]}
              >
                {orderData.isOrderDataForUpdate
                  ? getTranslation("Update Order")
                  : getTranslation("Submit Order")}
              </button>
            )}
          </Box>
        )}
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} xl={3} item>
        <RequestPageSidePanel
          setCurrentStepIndex={setCurrentStepIndex}
          currentStepIndex={currentStepIndex}
          handleNext={handleNext}
          handlePrev={handlePrev}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          progressText={progressText}
          steps={steps}
        />
      </Grid>
      <LoadingSpinner open={isLoading || isDownloadPending} />
      <ResultComponent
        modalWidth={750}
        status="success"
        title={
          orderData.isOrderDataForUpdate
            ? getResultModalTranslation("Successfully updated order")
            : getResultModalTranslation("Successfully created order")
        }
        subTitle={
          <p>
            {" "}
            {getResultModalTranslation("Order number")}:{" "}
            <span className="font-bold text-[#1976D2]">
              {orderNumber || "N/A"}
            </span>
          </p>
        }
        buttons={buttons}
        openModal={orderData.successfullyCreatedOrder}
        onCancel={handleClearTestOrderInputs}
      />
      {!enableVolumeCheck && (
        <RegularVolumeCheckerModal
          savedInvalidText={savedInvalidText}
          setCurrentStepIndex={setCurrentStepIndex}
        />
      )}

      {enableVolumeCheck && (
        <VolumeCheckerModal
          savedInvalidText={savedInvalidText}
          setCurrentStepIndex={setCurrentStepIndex}
        />
      )}
      <DuplicatedTestOrderWarningModal
        duplicatedTestCreator={duplicatedTestCreator}
        isLoading={isLoading}
        onOk={handleCreateTestOrder}
        open={showDuplicatedTestOrderModal}
        handleCloseModal={handleCloseDuplicatedTestOrderModal}
        handleClearTestOrderInputs={handleClearTestOrderInputs}
      />
    </Grid>
  );
};

export default FormContent;
