import { Box, Grid, Skeleton, Typography } from "@mui/material";
import QRCode from "react-qr-code";
import { orderStatusTagColor as orderStatusTextColor } from "../labtest/data";
import { useLocation } from "react-router-dom";
import moment from "moment";
import abcJktLogo from "assests/abc-jkt-logo.png";
import useTranslation from "hooks/useTranslation";

const HeaderContent = (props) => {
  const {
    cancelReason,
    clientDetails,
    isLoading,
    orderNumber,
    orderStatus,
    handlePrintTestOrder,
    orderDate,
  } = props;
  const location = useLocation();
  const lowerCasedOrderStatus = orderStatus?.toLowerCase();
  const isOrderStatusCancelled = lowerCasedOrderStatus === "canceled";
  const isOrderStatusHold = lowerCasedOrderStatus === "hold";
  const isOrderStatusRejected = lowerCasedOrderStatus === "rejected";
  const inReceivingRoute = location.pathname.includes("receiving");
  const { getTranslation, getGlobalTranslation } =
    useTranslation("RequestPage");
  const showCancellationReason =
    isOrderStatusCancelled || isOrderStatusHold || isOrderStatusRejected;

  const printTestOrder = () => {
    if (inReceivingRoute) {
      handlePrintTestOrder();
    }
  };

  const labelStyle = { color: "darkBlue.main", fontSize: "11pt" };
  const valueStyle = { fontWeight: "bold", fontSize: "12pt" };

  return (
    <Box>
      <Grid sx={{ marginBottom: 5 }} container>
        <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
          <Typography
            sx={{
              color: "darkBlue.main",
              marginBottom: "0.5rem",
              fontSize: "14pt",
              fontWeight: "bold",
            }}
            variant="h4"
          >
            eRequest Client - {getGlobalTranslation("Order details")}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="p" sx={labelStyle}>
              {getGlobalTranslation("Order date")}
            </Typography>
            <span className="ml-20">:</span>
            <p className="ml-4 text-[11pt]">
              {moment(orderDate).format("DD/MM/YYYY")}
            </p>
          </Box>
        </Grid>
        <Grid xs={2} sm={2} md={2} lg={2} xl={2}>
          <img
            src={abcJktLogo}
            className="object-cover h-[1.4cm] w-[3.7cm]"
            alt="ABC Logo"
          />
        </Grid>
      </Grid>
      <Grid rowSpacing={2} columnSpacing={1} container>
        <Grid
          sx={{ display: "flex", flexDirection: "column" }}
          xs={6}
          sm={6}
          md={2}
          lg={2}
          xl={2}
          item
        >
          <Typography variant="p" sx={labelStyle}>
            {getTranslation("Client no")}
          </Typography>
          <Typography variant="h5" sx={valueStyle}>
            {isLoading ? (
              <Skeleton variant="rounded" width={210} height={25} />
            ) : (
              clientDetails?.clientCode
            )}
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: {
              sm: "flex",
              xs: "flex",
              md: "none",
              lg: "none",
              xl: "none",
            },
            flexDirection: "column",
          }}
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          item
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              flexWrap: "wrap",
              gap: "1rem",
            }}
          >
            {isLoading ? (
              <Skeleton variant="rounded" width={80} height={80} />
            ) : (
              <QRCode
                onClick={printTestOrder}
                style={{
                  cursor: inReceivingRoute ? "pointer" : "auto",
                  display: orderNumber === null ? "none" : "block",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
                size={80}
                value={orderNumber + ""}
              />
            )}
            <Box>
              <Typography variant="p" sx={labelStyle}>
                {getTranslation("Order no")}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  width: "max-content",
                  color: "black",
                  marginTop: "0.3rem",
                  fontSize: "1.3rem",
                }}
              >
                {isLoading && (
                  <Skeleton variant="rounded" width={100} height={25} />
                )}
                {!isLoading && orderNumber === null ? (
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      color: "softBlue.secondary",
                      fontWeight: "bold",
                    }}
                    variant="p"
                  >
                    {"[pending]"}
                  </Typography>
                ) : (
                  orderNumber
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                  backgroundColor: "#EBEFF8",
                  padding: "0.1rem 0.3rem",
                  color: orderStatusTextColor(orderStatus).color,
                }}
                variant="p"
              >
                {orderStatus?.toUpperCase()}
              </Typography>
              {showCancellationReason && (
                <Box sx={{ marginTop: "0.3rem" }}>
                  <Typography sx={{ color: "darkBlue.main" }} variant="p">
                    {getGlobalTranslation("Reason")}:{" "}
                    <Typography sx={{ color: "black" }} variant="p">
                      {cancelReason}
                    </Typography>
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          sx={{ display: "flex", flexDirection: "column" }}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          item
        >
          <Typography variant="p" sx={labelStyle}>
            {getTranslation("Client name")}
          </Typography>
          <Typography variant="p" sx={valueStyle}>
            {isLoading ? (
              <Skeleton variant="rounded" width={210} height={25} />
            ) : (
              clientDetails?.clientName
            )}
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: {
              sm: "none",
              xs: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            flexDirection: "column",
          }}
          xs={6}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          item
        >
          <Box sx={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
            {isLoading ? (
              <Skeleton variant="rounded" width={80} height={80} />
            ) : (
              <QRCode
                onClick={printTestOrder}
                style={{
                  cursor: inReceivingRoute ? "pointer" : "auto",
                  display: orderNumber === null ? "none" : "block",
                  marginRight: "0.7rem",
                }}
                size={80}
                value={orderNumber + ""}
              />
            )}
            <Box>
              <Typography variant="p" sx={labelStyle}>
                {getTranslation("Order no")}
              </Typography>
              <Typography variant="h5" sx={valueStyle}>
                {isLoading && (
                  <Skeleton variant="rounded" width={100} height={25} />
                )}
                {!isLoading && orderNumber === null ? (
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      color: "softBlue.secondary",
                      fontWeight: "bold",
                    }}
                    variant="p"
                  >
                    {"[pending]"}
                  </Typography>
                ) : (
                  orderNumber
                )}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    backgroundColor: "#EBEFF8",
                    padding: "0.1rem 0.3rem",
                    color: orderStatusTextColor(orderStatus).color,
                  }}
                  variant="p"
                >
                  {orderStatus?.toUpperCase()}
                </Typography>
              </Box>
            </Box>
          </Box>
          {!isLoading && showCancellationReason && (
            <Box sx={{ marginTop: "0.3rem", marginLeft: "4.2rem" }}>
              <Typography
                sx={{ color: "darkBlue.main", fontSize: "11pt" }}
                variant="p"
              >
                {getGlobalTranslation("Reason")}:{" "}
                <Typography
                  sx={{ color: "black", fontSize: "11pt" }}
                  variant="p"
                >
                  {cancelReason}
                </Typography>
              </Typography>
            </Box>
          )}
          {isLoading && showCancellationReason && (
            <Skeleton variant="rounded" width={140} height={20} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeaderContent;
