const getTestDetailsRecursively = (data) => {
  let testDetailsData = [];

  data?.child.map((item) => {
    if (item?.testDetails?.labTestSpecimens) {
      item?.testDetails?.labTestSpecimens?.map((specimenData) => {
        testDetailsData.push({
          specimenId: specimenData.id,
          requiresBloodDraw: specimenData?.requiresBloodDraw,
          requiresSampleOrigin: specimenData?.requiresSampleOrigin,
          specimenExpirationInHours: item?.testDetails?.maxSpecimenAgeInHours,
        });
      });
    }

    if (item?.child && item?.child?.length > 0) {
      const childTestDetails = getTestDetailsRecursively(item);
      testDetailsData.push(...childTestDetails);
    }
  });

  return testDetailsData;
};

export default getTestDetailsRecursively;
