import {
  Alert,
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Button, Popover } from "antd";
import truncateString from "components/jkt/global/truncateString";
import React, { useEffect, useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SelectedDiagnosisTable from "./selectedDiagnosisTable";
import { getIcd10CodesLookUp } from "store";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import notification from "components/jkt/global/openNotification";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import useTranslation from "hooks/useTranslation";

const DiagnosisInputs = () => {
  const [diagnosisOptions, setDiagnosisOptions] = useState([]);
  const [icdInput, setIcdInput] = useState("");
  const dispatch = useDispatch();
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const isIcdInputEmpty = icdInput.trim().length === 0;
  const [selectedDiagnosisId, setSelectedDiagnosisId] = useState(null);
  const [diagnosisInput, setDiagnosisInput] = useState({
    id: nanoid(),
    displayName: "",
  });
  const { getTranslation, getGlobalTranslation } =
    useTranslation("RequestPage");
  const recentDisplayName = multiStepFormInputs.diagnosesList.map(
    (diagnosis) => diagnosis.displayName
  ); //Getting the all saved cinical diagnosis display name that is already added

  const isDiagnosisInputPresent = recentDisplayName.includes(
    diagnosisInput.displayName
  ); //Checking if the manually typed diagnosis is already saved

  const handleIcdChange = (e) => {
    //Getting the manually typed ICD-10 Code input
    setIcdInput(e.target.value);
  };

  const handleSelectDiagnosis = (selectedDiagnosis) => {
    //Function for adding new clinical diagnosis
    const isSelectedDiagnosisPresent = recentDisplayName.includes(
      selectedDiagnosis.displayName
    );
    if (!isSelectedDiagnosisPresent) {
      //Adding of new clinical diagnosis that is good to add
      dispatch(
        handleChangeMultiFields({
          diagnosesList: [
            ...multiStepFormInputs.diagnosesList,
            {
              id: selectedDiagnosis.id,
              displayName: selectedDiagnosis.displayName,
            },
          ],
        })
      );
      dispatch(
        handleChangeSavedFields({
          isClinicalDiagnosisEmpty: false,
        })
      );
      setIcdInput("");
      notification.success({
        message: getGlobalTranslation("Added Clinical Diagnosis"),
        description: `[${selectedDiagnosis.displayName}] ${getTranslation(
          "added successfully"
        )}`,
      });
    }

    if (isSelectedDiagnosisPresent) {
      //Validation if the selected diagnosis is already added, if it is then display a warning toast notification
      return notification.warning({
        message: "Unable to add Clinical Diagnosis",
        description: `[${selectedDiagnosis.displayName}] is already added`,
      });
    }
  };

  const handleDiagnosisChange = (e) => {
    //Getting the clinical diagnosis dropdown search input value
    setDiagnosisInput({ ...diagnosisInput, [e.target.name]: e.target.value });
  };

  const handleEnterDiagnosis = (e) => {
    //Adding of Clinical diagnosis using enter key
    if (
      e.key === "Enter" &&
      selectedDiagnosisId === null &&
      !isDiagnosisInputPresent &&
      diagnosisInput.displayName !== ""
    ) {
      dispatch(
        handleChangeSavedFields({
          isClinicalDiagnosisEmpty: false,
        })
      );
      notification.success({
        message: getGlobalTranslation("Added Clinical Diagnosis"),
        description: `[${diagnosisInput.displayName}] ${getTranslation(
          "added successfully"
        )}`,
      });
      dispatch(
        handleChangeMultiFields({
          diagnosesList: [...multiStepFormInputs.diagnosesList, diagnosisInput],
        })
      );
      setDiagnosisInput({
        id: nanoid(),
        displayName: "",
      });
      e.preventDefault();
      setSelectedDiagnosisId(null);
      dispatch(
        handleChangeSavedFields({
          isClinicalDiagnosisEmpty: false,
        })
      );
    }
    if (e.key === "Enter" && selectedDiagnosisId !== null) {
      if (isDiagnosisInputPresent) {
        return notification.warning({
          message: "Unable to add Clinical Diagnosis",
          description: `You can't use [${diagnosisInput.displayName}], because it is already added.`,
        });
      }
      dispatch(
        handleChangeMultiFields({
          diagnosesList: multiStepFormInputs.diagnosesList.map((diagnosis) =>
            diagnosis.id === selectedDiagnosisId
              ? { ...diagnosis, ...diagnosisInput }
              : diagnosis
          ),
        })
      );
      notification.success({
        message: "Updated Clinical Diagnosis",
        description: "Successfully updated data!",
      });
      setDiagnosisInput({
        id: nanoid(),
        displayName: "",
      });
      setSelectedDiagnosisId(null);
      dispatch(
        handleChangeSavedFields({
          isClinicalDiagnosisEmpty: false,
        })
      );
      e.preventDefault();
    }

    if (
      e.key === "Enter" &&
      isDiagnosisInputPresent &&
      diagnosisInput.displayName !== ""
    ) {
      return notification.warning({
        message: "Unable to add Clinical Diagnosis",
        description: `[${diagnosisInput.displayName}] is already added`,
      });
    }
  };

  const handleDiscardDiagnosisChanges = () => {
    //Function for discarding clinical diagnosis changes while updating its data
    setSelectedDiagnosisId(null);
    setDiagnosisInput({
      id: nanoid(),
      displayName: "",
    });
  };

  const handleAddDiagnosis = (e) => {
    //For adding new clinical diagnosis, using a button that is inside the textarea
    e.preventDefault();
    dispatch(
      handleChangeSavedFields({
        isClinicalDiagnosisEmpty: false,
      })
    );

    if (selectedDiagnosisId !== null) {
      dispatch(
        handleChangeMultiFields({
          diagnosesList: multiStepFormInputs.diagnosesList.map((diagnosis) =>
            diagnosis.id === selectedDiagnosisId
              ? { ...diagnosis, ...diagnosisInput }
              : diagnosis
          ),
        })
      );

      notification.success({
        message: getGlobalTranslation("Added Clinical Diagnosis"),
        description: "Successfully updated data!",
      });
      setDiagnosisInput({
        id: nanoid(),
        displayName: "",
      });
      setSelectedDiagnosisId(null);
    }
    if (
      selectedDiagnosisId === null &&
      !isDiagnosisInputPresent &&
      diagnosisInput.displayName !== ""
    ) {
      notification.success({
        message: getGlobalTranslation("Added Clinical Diagnosis"),
        description: `[${diagnosisInput.displayName}] ${getTranslation(
          "added successfully"
        )}`,
      });
      dispatch(
        handleChangeMultiFields({
          diagnosesList: [...multiStepFormInputs.diagnosesList, diagnosisInput],
        })
      );
      setDiagnosisInput({
        id: nanoid(),
        displayName: "",
      });
      setSelectedDiagnosisId(null);
    }
    if (isDiagnosisInputPresent && selectedDiagnosisId === null) {
      return notification.warning({
        message: "Unable to add Clinical Diagnosis",
        description: `[${diagnosisInput.displayName}] is already added`,
      });
    }
  };

  const handleFetchIcd10Codes = () => {
    //Fetching of ICD-10 Code options
    dispatch(getIcd10CodesLookUp()).then((res) => {
      if (res?.payload?.success) {
        setDiagnosisOptions(res?.payload?.data);
      }
    });
  };

  useEffect(() => {
    handleFetchIcd10Codes();
  }, []);

  return (
    <>
      <Typography
        variant="p"
        sx={{ fontWeight: "bold", color: "darkBlue.main" }}
        className="required"
      >
        {getTranslation("Clinical diagnosis")}
      </Typography>
      <Typography
        variant="p"
        sx={{
          fontSize: "0.8rem",
          color: "darkBlue.main",
          fontStyle: "italic",
        }}
      >
        {getTranslation(
          "Please put N/A if there are no clinical diagnosis that will be added"
        )}
      </Typography>
      <Box
        sx={{
          backgroundColor: "grayishBlue.main",
          padding: "1.3rem 1.4rem",
          marginTop: "0.5rem",
        }}
      >
        {selectedDiagnosisId !== null && (
          <Alert
            variant="outlined"
            sx={{
              margin: "0.5rem 0",
              width: {
                xs: "100%",
                sm: "100%",
                md: "97%",
                lg: "97%",
                xl: "97%",
              },
            }}
            severity="info"
          >
            You are in edit mode
          </Alert>
        )}
        <TextField
          onKeyDown={(e) => handleEnterDiagnosis(e)}
          onChange={handleDiagnosisChange}
          name="displayName"
          value={diagnosisInput.displayName}
          sx={{
            position: "relative",
            width: "98%",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                border: "2px solid",
                borderColor: "darkBlue.secondary",
              },
            },
            borderRadius: "5px",
            outline: "none",
            height: "auto",
          }}
          placeholder={getTranslation("Write your diagnosis here")}
          size="small"
          multiline
          rows={3}
          variant="outlined"
          inputProps={{
            sx: {
              "&::placeholder": {
                fontSize: "14px",
                fontWeight: 500,
                fontStyle: "italic",
              },
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    gap: "0.6rem",
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  }}
                >
                  <Button
                    onClick={handleAddDiagnosis}
                    htmlType="button"
                    type="primary"
                    className="submitBtn"
                    disabled={diagnosisInput.displayName.trim().length === 0}
                  >
                    {selectedDiagnosisId !== null ? "Save" : "Enter"}
                  </Button>
                  {selectedDiagnosisId !== null && (
                    <Button
                      onClick={handleDiscardDiagnosisChanges}
                      htmlType="button"
                      type="primary"
                      danger
                    >
                      {getTranslation("Cancel")}
                    </Button>
                  )}
                </Box>
              </InputAdornment>
            ),
          }}
        />
        <Autocomplete
          options={diagnosisOptions}
          disableClearable
          inputValue={icdInput !== "" ? icdInput : ""}
          clearOnBlur
          size="small"
          value={icdInput}
          noOptionsText={
            !isIcdInputEmpty
              ? `${getTranslation(
                  "No labtest were found for your search"
                )}: [${icdInput}]`
              : "No options to be displayed"
          }
          getOptionLabel={(diagnosis) =>
            diagnosis.displayName ? diagnosis.displayName : ""
          }
          onChange={(event, newInputValue) => {
            handleSelectDiagnosis(newInputValue);
          }}
          renderOption={(props, diagnosis) => (
            <>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  },
                }}
              >
                <Popover
                  placement="rightTop"
                  autoAdjustOverflow
                  zIndex={99999}
                  content={`${diagnosis.displayName}`}
                  title={getTranslation("Icd 10 code details")}
                >
                  <li style={{ margin: "0.2rem" }} {...props}>
                    {diagnosis.displayName.length > 21
                      ? diagnosis.displayName.substring(0, 25) + "..."
                      : diagnosis.displayName}
                  </li>
                </Popover>
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                }}
              >
                <li style={{ margin: "0.2rem" }} {...props}>
                  {truncateString(diagnosis.displayName)}
                </li>
              </Box>
            </>
          )}
          renderInput={(params) => (
            <TextField
              onChange={handleIcdChange}
              sx={{
                width: {
                  xs: "98%",
                  sm: "98%",
                  md: "60%",
                  lg: "60%",
                  xl: "60%",
                },
                marginTop: "1rem",
                backgroundColor: "white",
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.secondary",
                  },
                },
                borderRadius: "5px",
                outline: "none",
              }}
              {...params}
              placeholder={getTranslation("Keyword for ICD-10 code")}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <Box sx={{ overflow: "hidden", opacity: 0 }}>
                      {params.InputProps.endAdornment}
                    </Box>
                    <InputAdornment
                      sx={{ transform: "translateX(30px)" }}
                      position="end"
                    >
                      <SearchOutlinedIcon
                        sx={{
                          color: "lightBlue.main",
                          pointerEvents: "none",
                        }}
                      />
                    </InputAdornment>
                  </>
                ),
              }}
            />
          )}
        />
        <SelectedDiagnosisTable
          diagnosisOptions={diagnosisOptions}
          setDiagnosisInput={setDiagnosisInput}
          setSelectedDiagnosisId={setSelectedDiagnosisId}
        />
      </Box>
    </>
  );
};

export default DiagnosisInputs;
