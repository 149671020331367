import { useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  LinearProgress,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch } from "react-redux";
import { getInventoryItemsLookup } from "store";
import { clientLookUp } from "store";
import { sortDataAlphabetically } from "utils/sortDataAlphabetically";
import AddedInventoryItemsDisplay from "./addedInventoryItemsDisplay";
import notification from "components/jkt/global/openNotification";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { FloatButton, Modal } from "antd";
import { parsedClientDetails } from "utils/parsedClientDetails";
import { isDataEmpty } from "utils/isDataEmpty";
import { showInputErrorMessage } from "utils/showInputErrorMessage";

const minItemQuantity = 1;

const defaultRequestItemInputValues = {
  id: null,
  inventoryItemId: null,
  quantityRequested: 1,
  inventoryItemNameValue: "",
  purpose: "",
};

const SupplyFormInputs = () => {
  const [clientsData, setClientsData] = useState([]);
  const { isClientAbcLab } = parsedClientDetails();
  const [requestItemInputs, setRequestItemInputs] = useState(
    defaultRequestItemInputValues
  );
  const [requestedItems, setRequestedItems] = useState([]);
  const [inventoryItemsData, setInventoryItemsData] = useState([]);
  const [openRequestedItems, setOpenRequestedItems] = useState(false);
  const [selectedClientCode, setSelectedClientCode] = useState(null);
  const [requestedBy, setRequestedBy] = useState("");
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isItemQuantityInvalid =
    requestItemInputs.quantityRequested < minItemQuantity;
  const hasClientsData = clientsData?.length > 0;
  const hasInventoryItemsData = inventoryItemsData?.length > 0;
  const disableAddButton = requestItemInputs.inventoryItemId === null;
  const isItemBeingUpdated = requestItemInputs.id !== null;
  const dispatch = useDispatch();
  const disableDecreaseButton =
    requestItemInputs.quantityRequested <= minItemQuantity;

  //Fetching of inventory and client data that will be used as options
  useEffect(() => {
    dispatch(getInventoryItemsLookup()).then((res) => {
      const isFetchSuccessful = res?.payload?.success;

      if (isFetchSuccessful) {
        setInventoryItemsData(res?.payload?.data);
      }
    });

    if (isClientAbcLab) {
      dispatch(clientLookUp()).then((res) => {
        const isFetchSuccessful = res?.payload?.success;
        if (isFetchSuccessful) {
          setClientsData(res?.payload?.data);
        }
      });
    }
  }, [dispatch, isClientAbcLab]);

  const formattedClientOptions =
    hasClientsData &&
    sortDataAlphabetically(clientsData, "clientName")?.map((item) => ({
      label: item?.displayName,
      id: item?.clientCode,
    }));

  const showInputError = (input) => {
    if (isDataEmpty(input) && isFormDirty) {
      return true;
    }
    return false;
  };

  const validateFormInputs = () => {
    if (isDataEmpty(selectedClientCode) || isDataEmpty(requestedBy)) {
      return setIsFormDirty(true);
    }
  };

  const checkedInventoryItemOptions = 
    hasInventoryItemsData &&
    inventoryItemsData?.sort((a, b) =>
      a?.categoryName?.localeCompare(b?.categoryName)
    );

  const onRequestByInputChange = (e) => {
    setRequestedBy(e.target.value);
  };

  const renderSelectedInventoryName = (inventoryId) => {
    const isInventoryIdNull = inventoryId === null;

    const inventoryItemName = isInventoryIdNull
      ? ""
      : checkedInventoryItemOptions?.find((item) => item.id === inventoryId)
          ?.name;

    return inventoryItemName;
  };

  const renderSelectedClientName = (clientCode) => {
    const isClientCodeNull = clientCode === null;

    const clientName = isClientCodeNull
      ? ""
      : formattedClientOptions?.find((item) => item?.id === clientCode)?.label;

    return clientName;
  };

  const decrementItemQuantity = () => {
    const isItemQuantiyReachesMin =
      requestItemInputs.quantityRequested === minItemQuantity;
    if (isItemQuantiyReachesMin) return;
    setRequestItemInputs({
      ...requestItemInputs,
      quantityRequested: requestItemInputs.quantityRequested - 1,
    });
  };

  const incrementItemQuantity = () => {
    const isItemQuantityEmpty = isNaN(requestItemInputs.quantityRequested);
    if (isItemQuantityEmpty) {
      setRequestItemInputs({
        ...requestItemInputs,
        quantityRequested: 1,
      });
    } else {
      setRequestItemInputs({
        ...requestItemInputs,
        quantityRequested: requestItemInputs.quantityRequested + 1,
      });
    }
  };

  const renderWarningMessage = (warningMessage) => {
    return (
      <p className="text-red-600 text-xs text-center mt-1">{warningMessage}</p>
    );
  };

  const disableAddedOption = (option) => {
    return requestedItems.some((item) => item.inventoryItemId === option.id);
  };

  const clearRequestItemInputs = () => {
    setRequestItemInputs(defaultRequestItemInputValues);
  };

  const addRequestItem = () => {
    const requestItemsToBeAdded = {
      ...requestItemInputs,
      id: Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1),
    };
    setRequestedItems([...requestedItems, requestItemsToBeAdded]);
    clearRequestItemInputs();
    notification.success({
      message: "Successfully added item",
    });
  };

  const saveUpdateChanges = () => {
    const updatedRequestedItems = requestedItems.map((item) =>
      item.id === requestItemInputs.id
        ? {
            ...requestItemInputs,
          }
        : item
    );
    clearRequestItemInputs();
    setRequestedItems(updatedRequestedItems);
    notification.success({
      message: "Successfully updated item",
    });
  };

  const selectRequestItemToBeUpdated = (selectedRequestItemData) => {
    setRequestItemInputs(selectedRequestItemData);
  };

  const removeItem = (itemId) => {
    const filteredRequestedItems = requestedItems.filter(
      (item) => item.id !== itemId
    );
    setRequestedItems(filteredRequestedItems);
    notification.success({
      message: "Successfully removed item",
    });
  };

  const clearRequestedItems = () => {
    setRequestedItems([]);
  };

  useEffect(() => {
    const clearSelectedInventoryId =
      requestItemInputs.inventoryItemNameValue.trim().length === 0 ||
      renderSelectedInventoryName(requestItemInputs.inventoryItemId) !==
        requestItemInputs.inventoryItemNameValue;
    if (clearSelectedInventoryId) {
      setRequestItemInputs({
        ...requestItemInputs,
        inventoryItemId: null,
      });
    }
  }, [requestItemInputs.inventoryItemNameValue]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexWrap: "wrap",
        my: 4,
      }}
    >
      <Box
        sx={{
          width: "25rem",
          padding: "1.2rem 0.8rem",
          backgroundColor: "whitesmoke",
          borderRadius: "0.5rem",
          boxShadow: "1px 1px 5px",
          position: "relative",
        }}
      >
        {isLoading && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          />
        )}
        <h1 className="text-center text-2xl font-bold my-3">
          Supplies Request
        </h1>
        {isClientAbcLab && (
          <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <Box>
              <label className="required">Client</label>
              <Autocomplete
                disabled={!hasClientsData}
                disableClearable={selectedClientCode === null ? true : false}
                disablePortal
                options={formattedClientOptions}
                value={renderSelectedClientName(selectedClientCode)}
                sx={{
                  width: "100%",
                  marginTop: "0.2rem",
                  backgroundColor: "white",
                }}
                onChange={(event, newValue) => {
                  setSelectedClientCode(
                    isDataEmpty(newValue) ? null : newValue.id
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={showInputError(selectedClientCode)}
                    label="Select Client..."
                  />
                )}
              />
              {showInputErrorMessage(
                selectedClientCode,
                isFormDirty,
                "Client Code"
              )}
            </Box>
            <Box>
              <label className="required">Requested By</label>
              <TextField
                error={showInputError(requestedBy)}
                label="Requested By..."
                variant="outlined"
                placeholder="Please input who requested supply order..."
                onChange={onRequestByInputChange}
                value={requestedBy}
                sx={{
                  width: "100%",
                  marginTop: "0.2rem",
                  backgroundColor: "white",
                }}
              />
              {showInputErrorMessage(requestedBy, isFormDirty, "Requested By")}
            </Box>
          </Box>
        )}
        <Divider sx={{ mt: 2 }}>
          <Chip label="Request Item Informations" size="small" />
        </Divider>
        {isItemBeingUpdated && (
          <Alert sx={{ mt: 1 }} severity="info">
            Currently updating item
          </Alert>
        )}
        {/* Item select dropdown */}
        <Box>
          <label className="required">Item</label>
          <Autocomplete
            disableClearable={
              requestItemInputs.inventoryItemId === null ? true : false
            }
            disabled={!hasInventoryItemsData}
            disablePortal
            options={checkedInventoryItemOptions}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            groupBy={(option) => option.categoryName}
            getOptionDisabled={disableAddedOption}
            inputValue={
              requestItemInputs.inventoryItemNameValue === ""
                ? ""
                : requestItemInputs.inventoryItemNameValue
            }
            value={requestItemInputs.inventoryItemNameValue}
            sx={{
              width: "100%",
              marginTop: "0.2rem",
              backgroundColor: "white",
            }}
            onChange={(event, newValue) => {
              setRequestItemInputs({
                ...requestItemInputs,
                inventoryItemId: isDataEmpty(newValue) ? null : newValue.id,
                inventoryItemNameValue: isDataEmpty(newValue)
                  ? ""
                  : newValue.name,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                value={requestItemInputs.inventoryItemNameValue}
                label="Select Inventory Item..."
                onChange={(e) =>
                  setRequestItemInputs({
                    ...requestItemInputs,
                    inventoryItemNameValue: e.target.value,
                  })
                }
              />
            )}
          />
        </Box>
        {/* Item quantity input */}
        <Box sx={{ mt: 2 }}>
          <label className="required">Item Quantity</label>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 3,
              marginTop: "0.2rem",
            }}
          >
            <Button
              disabled={disableDecreaseButton}
              sx={{ width: "100%" }}
              onClick={decrementItemQuantity}
              type="button"
              variant="contained"
            >
              <RemoveIcon />
            </Button>
            <TextField
              error={isItemQuantityInvalid}
              value={requestItemInputs.quantityRequested}
              onChange={(e) =>
                setRequestItemInputs({
                  ...requestItemInputs,
                  quantityRequested: parseInt(e.target.value.slice(0, 7)),
                })
              }
              type="number"
              sx={{ width: "100%", backgroundColor: "white" }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              size="small"
            />
            <Button
              sx={{ width: "100%" }}
              onClick={incrementItemQuantity}
              type="button"
              variant="contained"
            >
              <AddIcon />
            </Button>
          </Box>
        </Box>
        {isItemQuantityInvalid && renderWarningMessage("Invalid item quantity")}
        {/* Item purpose input */}
        <Box sx={{ mt: 2 }}>
          <label>Purpose</label>
          <Box sx={{ marginTop: "0.2rem" }}>
            <TextField
              value={requestItemInputs.purpose}
              onChange={(e) =>
                setRequestItemInputs({
                  ...requestItemInputs,
                  purpose: e.target.value,
                })
              }
              sx={{ width: "100%", backgroundColor: "white" }}
              label="Write the purpose of this request..."
              multiline
              rows={3}
            />
          </Box>
        </Box>
        <Button
          onClick={isItemBeingUpdated ? saveUpdateChanges : addRequestItem}
          disabled={disableAddButton || isLoading}
          sx={{ width: "100%", mt: 2 }}
          type="button"
          variant="contained"
        >
          {isItemBeingUpdated ? "Save Changes" : " Add Item"}
        </Button>
        {isItemBeingUpdated && (
          <Button
            onClick={clearRequestItemInputs}
            sx={{ width: "100%", mt: 1 }}
            type="button"
            variant="contained"
            color="error"
          >
            Discard Changes
          </Button>
        )}
      </Box>
      {/* Desktop view */}
      <div className="hidden md:flex md:flex-col">
        <AddedInventoryItemsDisplay
          selectRequestItemToBeUpdated={selectRequestItemToBeUpdated}
          renderSelectedInventoryName={renderSelectedInventoryName}
          requestedItems={requestedItems}
          removeItem={removeItem}
          selectedClientCode={selectedClientCode}
          clearRequestedItems={clearRequestedItems}
          requestedBy={requestedBy}
          validateFormInputs={validateFormInputs}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </div>
      {/* Mobile view */}
      <div className="block md:hidden">
        <Modal
          open={openRequestedItems}
          onCancel={() => setOpenRequestedItems(false)}
          footer={false}
        >
          <AddedInventoryItemsDisplay
            selectRequestItemToBeUpdated={selectRequestItemToBeUpdated}
            renderSelectedInventoryName={renderSelectedInventoryName}
            requestedItems={requestedItems}
            removeItem={removeItem}
            selectedClientCode={selectedClientCode}
            clearRequestedItems={clearRequestedItems}
            requestedBy={requestedBy}
            validateFormInputs={validateFormInputs}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </Modal>
        <FloatButton
          shape="circle"
          onClick={() => setOpenRequestedItems(true)}
          icon={<ShoppingCartOutlined />}
          type="primary"
          style={{ right: 24, top: 600 }}
          badge={{
            count: requestedItems.length > 0 ? requestedItems.length : "",
          }}
        />
      </div>
    </Box>
  );
};

export default SupplyFormInputs;
