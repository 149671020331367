import * as React from "react";
import { Modal, Select, Form, Input, Button, Popconfirm } from "antd";
import { useDispatch } from "react-redux";
import {
  createSampleCollector,
  deleteSampleCollector,
  getSampleCollectorTypes,
  updateSampleCollector,
} from "store";
import useCheckPermission from "hooks/useCheckPermission";
import { checkedData } from "utils/checkedData";
import { convertedFullName } from "utils/convertedFullName";
import notification from "components/jkt/global/openNotification";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";

const CreateUpdateSampleCollector = (props) => {
  const {
    handleCancel,
    openCreateUpdate,
    handleSubmit,
    isForUpdate,
    idForUpdate,
  } = props;

  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToDelete = handleVerifyPermission(
    "Laboratory:SampleCollector:Delete"
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [sampleCollectorTypes, setSampleCollectorTypes] = React.useState([]);
  const sampleCollectorName = Form.useWatch("sampleCollectorName", form);

  const LoadUpdateData = React.useCallback(() => {
    form.setFieldsValue({
      collectorType: idForUpdate.collectorType,
      sampleCollectorName: `${idForUpdate.firstName} ${checkedData(
        idForUpdate.lastName
      )}`,
    });
  }, [form, idForUpdate]);

  React.useEffect(() => {
    if (isForUpdate) {
      LoadUpdateData();
    }
  }, [isForUpdate, LoadUpdateData]);

  const clearFormInputs = () => {
    form.setFieldsValue({
      collectorType: "Phlebotomist",
      sampleCollectorName: "",
    });
  };

  const closeModal = () => {
    handleCancel();
    clearFormInputs();
  };

  const onFinish = (values) => {
    if (isForUpdate) {
      if (
        updateSampleCollector.pending().type ===
        "sample-collectors/update-sample-collectors/pending"
      ) {
        setLoading(true);
      }
      const { firstName, lastName } = convertedFullName(sampleCollectorName);

      dispatch(
        updateSampleCollector({
          collectorType: values?.collectorType,
          firstName: firstName,
          id: idForUpdate.id,
          lastName: lastName,
        })
      ).then((val) => {
        const responseData = val?.payload?.data;
        if (val.payload?.success) {
          handleSubmit();
          clearFormInputs();
          setLoading(false);
          notification.success({
            message: "Updated Sample Collector",
            description: `Successfully updated ${
              responseData?.firstName
            } ${checkedData(responseData?.lastName)}`,
          });
        }
        if (!val.payload?.success) {
          notification.error({
            message: "Failed to Update Sample Collector",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
          setLoading(false);
        }
      });
    } else {
      if (
        createSampleCollector.pending().type ===
        "sample-collectors/create-sample-collectors/pending"
      ) {
        setLoading(true);
      }
      const { firstName, lastName } = convertedFullName(sampleCollectorName);

      dispatch(
        createSampleCollector({
          collectorType: values?.collectorType,
          firstName: firstName,
          lastName: lastName,
        })
      ).then((val) => {
        const responseData = val?.payload?.data;

        if (val.payload?.success) {
          handleSubmit();
          clearFormInputs();
          setLoading(false);
          notification.success({
            message: "Created Sample Collector",
            description: `Successfully created ${
              responseData?.firstName
            } ${checkedData(responseData?.lastName)}`,
          });
        }
        if (!val.payload?.success) {
          notification.error({
            message: "Failed to Create Sample Collector",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
          setLoading(false);
        }
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    errorInfo.errorFields.map((item) => {
      return notification.error({
        message: "Required Field",
        description: item?.errors[0].props.children,
      });
    });
  };

  const handleClickDelete = () => {
    //HANDLE LOADING
    if (
      deleteSampleCollector.pending().type ===
      "sample-collectors/delete-sample-collectors/pending"
    ) {
      setLoading(true);
    }
    dispatch(deleteSampleCollector(idForUpdate.id)).then((val) => {
      if (val.payload?.success) {
        handleSubmit();
        setLoading(false);
        notification.success({
          message: "Deleted Sample Collector",
          description: `Successfully deleted ${
            idForUpdate.firstName + " " + idForUpdate.lastName
          }`,
        });
      }
      if (!val.payload?.success) {
        notification.error({
          message: "Failed to Delete Sample Collector",
          description: errorMessagesDescription(
            val.payload?.response.data.errorMessages
          ),
        });
        setLoading(false);
      }
    });
  };

  React.useEffect(() => {
    dispatch(getSampleCollectorTypes()).then((res) => {
      setSampleCollectorTypes(res.payload);
    });
  }, [dispatch]);

  const optionSampleCollectorType = sampleCollectorTypes.map((type, index) => {
    return { value: type, label: type };
  });

  return (
    <Modal
      open={openCreateUpdate}
      title={
        isForUpdate ? "Update Sample Collector" : "Create Sample Collector"
      }
      onCancel={closeModal}
      footer={null}
    >
      <Form
        name="sampleCollector"
        initialValues={{
          collectorType: "Phlebotomist",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        {/* Status
        {isForUpdate && (
          <Form.Item label="Status" name="isActive" valuePropName="checked">
            <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
          </Form.Item>
        )} */}

        {/* Sample Collector Name */}
        <Form.Item
          className="mt-7"
          label="Sample Collector Name"
          name="sampleCollectorName"
          rules={[
            {
              required: true,
              message: <i>{"Please input Sample Collector Name."}</i>,
            },
          ]}
        >
          <Input placeholder="Sample Collector Name" />
        </Form.Item>

        {/* Collector Type */}
        <Form.Item
          label="Collector Type"
          name="collectorType"
          rules={[
            {
              required: true,
              message: <i>{"Please input Collector Type."}</i>,
            },
          ]}
        >
          <Select
            allowClear
            options={optionSampleCollectorType}
            placeholder={"Collector Type"}
          />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ margin: 2 }}
            className="submitBtn"
          >
            Submit
          </Button>
          {accessRightsToDelete && isForUpdate ? (
            <Popconfirm
              title="Delete the sample collector"
              description="Are you sure to delete?"
              onConfirm={handleClickDelete}
              okText="Yes"
              cancelText="No"
              okButtonProps={{ loading: loading, className: "submitBtn" }}
            >
              <Button loading={loading} type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          ) : null}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUpdateSampleCollector;
