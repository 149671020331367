import { Box, Grid, Skeleton, Typography } from "@mui/material";
import styles from "components/jkt/testRequest/forms/fourthStep/styles.module.css";
import moment from "moment";
import useTranslation from "hooks/useTranslation";
import { isDataEmpty } from "utils/isDataEmpty";

const TestProfileSectionTwo = (props) => {
  const {
    diagnosesList,
    sampleCollectorName,
    preparedBy,
    specimens,
    isLoading,
    testOrderDetails,
    specialHandlings,
    courierRemarks,
    testOrderNotes,
  } = props;

  const { getTranslation } = useTranslation("RequestPage");
  const testProfileDetails = [
    {
      id: 1,
      label: getTranslation("Sample Collector"),
      value: `${sampleCollectorName || ""}`,
    },
    {
      id: 2,
      label: getTranslation("Prepared by"),
      value: `${preparedBy || ""}`,
    },
  ];

  const isDateValid = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const renderCollectionSite = (collectionSite) => {
    if (collectionSite === null) {
      return "N/A";
    }
    return getTranslation(collectionSite);
  };

  return (
    <Grid sx={{ marginTop: "0.6rem" }} container>
      <Grid sx={{ marginRight: "auto" }} xs={6} sm={6} md={6} lg={6} xl={6}>
        <Typography
          variant="p"
          sx={{ fontWeight: "600", color: "darkBlue.main" }}
        >
          {getTranslation("Clinical diagnosis")}
        </Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <ul className={styles["diagnosis-list"]}>
            {diagnosesList &&
              diagnosesList.map((data) => (
                <li key={data.id}>&#x2022; {data.displayName}</li>
              ))}
          </ul>
        )}

        <Box className={styles["grid-container"]}>
          {testProfileDetails.map((info) => (
            <Box key={info.id} className={styles["grid-item"]}>
              <span className={styles.label}>{info.label}</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>
                {isLoading ? <Skeleton /> : info.value}
              </span>
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid xs={5} sm={5} md={5} lg={5} xl={5}>
        <Typography
          variant="p"
          sx={{ fontWeight: "600", color: "darkBlue.main" }}
        >
          {getTranslation("Special instructions")}
        </Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <ul className={styles["handling-list"]}>
            {specialHandlings &&
              specialHandlings.map((data) => {
                let specialInstruction = "";
                const statText = (
                  <li>
                    STAT - [
                    <span className="font-bold text-sm px-1">
                      {testOrderDetails
                        ?.filter((test) => test.isStat === true)
                        ?.map((data) => data.labTest.name)
                        .join(", ")}
                    </span>
                    ]
                  </li>
                );
                if (data.instructions.toLowerCase() === "stat") {
                  specialInstruction = statText;
                } else {
                  specialInstruction = `${data.instructions}`;
                }
                return <li key={data.id}>&#x2022; {specialInstruction}</li>;
              })}
          </ul>
        )}
        <Typography
          variant="p"
          sx={{
            fontWeight: "600",
            color: "darkBlue.main",
          }}
        >
          {getTranslation("Additional patient information")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            marginTop: "0.5rem",
          }}
        >
          {isLoading ? (
            <Skeleton />
          ) : testOrderDetails?.filter(
              (details) => details.patientTestRequirementDatas?.length > 0
            ).length > 0 ? (
            testOrderDetails
              ?.filter(
                (details) => details.patientTestRequirementDatas?.length > 0
              )
              .map((test) => (
                <Box
                  key={test?.labTest?.id}
                  sx={{
                    padding: "0.6rem",
                    backgroundColor: isLoading ? "" : "#EBEFF8",
                  }}
                >
                  <p className={styles["additional-info-text"]}>
                    For{" "}
                    <span className={styles["test-type"]}>
                      {test?.labTest?.name}
                    </span>{" "}
                    test
                  </p>
                  {testOrderDetails
                    .find((detail) => detail.testId === test?.labTest?.id)
                    ?.patientTestRequirementDatas[0]?.patientRequirementDataDetails.filter(
                      (dataDetail) => dataDetail.dataValue !== ""
                    )
                    .map((requirement) => {
                      const dataValue =
                        requirement.dataValue === "true"
                          ? "Yes"
                          : requirement.dataValue === "false"
                          ? "No"
                          : isDateValid(requirement.dataValue) &&
                            requirement.dataValue.length === 10
                          ? moment(requirement.dataValue).format("LL")
                          : requirement.dataValue;
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.2rem",
                            marginTop: "0.7rem",
                          }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              color: "darkBlue.main",
                              fontSize: "0.8rem",
                            }}
                          >
                            {requirement.description}
                          </Typography>
                          <Typography
                            variant="p"
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            {dataValue}
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>
              ))
          ) : (
            <Box
              sx={{
                backgroundColor: isLoading ? "" : "#EBEFF8",
                padding: "0.6rem",
              }}
            >
              <p className={styles["additional-info-text"]}>
                <span className={styles["test-type"]}>
                  {getTranslation("None")}
                </span>
              </p>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "6fr 24fr 20fr 20fr 40fr",
            marginTop: "1.2rem",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontWeight: "500",
              color: "grayishBlue.light",
            }}
          >
            No.
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontWeight: "500",
              color: "grayishBlue.light",
            }}
          >
            {getTranslation("Specimen type")}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontWeight: "500",
              color: "grayishBlue.light",
            }}
          >
            {getTranslation("Quantity")}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontWeight: "500",
              color: "grayishBlue.light",
            }}
          >
            {getTranslation("Collection Site")}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontWeight: "500",
              color: "grayishBlue.light",
            }}
          >
            {getTranslation("Notes")}
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: isLoading ? "" : "#EBEFF8",
            paddingBottom: "1rem",
            marginTop: "0.5rem",
            display: specimens?.length === 0 ? "none" : "block",
          }}
        >
          {isLoading ? (
            <Skeleton />
          ) : (
            specimens &&
            specimens.map((data, index) => (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "5fr 26fr 20fr 20fr 40fr",
                  padding: "0.3rem 0.5rem",
                }}
                key={data.id}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                  }}
                  variant="p"
                >
                  {index + 1}
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                  }}
                  variant="p"
                >
                  {data?.specimen?.name}
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                  }}
                  variant="p"
                >
                  {data?.specimenQuantity}{" "}
                  {data?.specimenQuantity !== "N/A" &&
                    data?.specimen?.unitOfMeasure}
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                  }}
                  variant="p"
                >
                  {renderCollectionSite(data?.collectionSite)}
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                  }}
                  variant="p"
                >
                  {data?.notes}
                </Typography>
              </Box>
            ))
          )}
        </Box>
      </Grid>
      {!isDataEmpty(courierRemarks) && (
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "block",
            mt: 2,
          }}
          item
        >
          <Typography
            variant="p"
            sx={{ fontWeight: "600", color: "darkBlue.main" }}
          >
            Courier Remarks
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <Box
              sx={{
                padding: "0.6rem",
                backgroundColor: isLoading ? "" : "#EBEFF8",
              }}
            >
              {courierRemarks}
            </Box>
          </Box>
        </Grid>
      )}
      {!isDataEmpty(testOrderNotes) && (
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "block",
            mt: 2,
          }}
        >
          <Typography
            variant="p"
            sx={{ fontWeight: "600", color: "darkBlue.main" }}
          >
            Test Order Notes
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <Box
              sx={{
                padding: "0.6rem",
                backgroundColor: isLoading ? "" : "#EBEFF8",
              }}
            >
              {testOrderNotes}
            </Box>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default TestProfileSectionTwo;
