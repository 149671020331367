export const timePeriodOptions = [
  {
    value: 0,
    label: "ALL DAY",
  },
  {
    value: 1,
    label: "AM",
  },
  {
    value: 2,
    label: "PM",
  },
];

export const requirementTypeOptions = [
  {
    value: 0,
    label: "Text",
  },
  {
    value: 1,
    label: "Number",
  },
  {
    value: 2,
    label: "Date",
  },
  {
    value: 3,
    label: "Checkbox",
  },
  {
    value: 4,
    label: "Radio",
  },
];

export const weekOptions = [
  { value: 0, label: "Sunday" },
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
];

export const getWeekDay = (schedule) => {
  switch (schedule) {
    case 0:
      return { label: "Sunday" };
    case 1:
      return { label: "Monday" };
    case 2:
      return { label: "Tuesday" };
    case 3:
      return { label: "Wednesday" };
    case 4:
      return { label: "Thursday" };
    case 5:
      return { label: "Friday" };
    case 6:
      return { label: "Saturday" };
    default:
      return schedule;
  }
};

export const getRequirementType = (requirement) => {
  switch (requirement) {
    case 0:
      return {
        requirementType: "Text",
      };
    case 1:
      return {
        requirementType: "Number",
      };
    case 2:
      return {
        requirementType: "Date",
      };
    case 3:
      return {
        requirementType: "Checkbox",
      };
    default: {
      return {
        requirementType: "Radio",
      };
    }
  }
};

export const orderStatusTagColor = (orderStatus) => {
  //Function for getting the tag color of an order status
  switch (orderStatus?.toLowerCase()) {
    case "canceled":
      return { color: "red" };
    case "pickedup":
      return { color: "blue" };
    case "accepted":
      return { color: "green" };
    case "rejected":
      return { color: "red" };
    case "inclientprocessing":
      return { color: "orange" };
    case "readyforpickup":
      return { color: "orange" };
    case "hold":
      return { color: "gold" };
    case "pending":
      return { color: "purple" };
    default:
      return { color: "default" };
  }
};

export const getStringWeekName = (number) => {
  //Getting the week name and color of the schedule of labtest
  switch (number) {
    case 0:
      return {
        color: "magenta",
        str: "Su",
        long: "Sunday",
      };
    case 1:
      return { color: "gold", str: "M", long: "Monday" };
    case 2:
      return { color: "lime", str: "T", long: "Tuesday" };
    case 3:
      return { color: "green", str: "W", long: "Wednesday" };
    case 4:
      return { color: "cyan", str: "Th", long: "Thursday" };
    case 5:
      return { color: "blue", str: "F", long: "Friday" };
    case 6:
      return { color: "purple", str: "S", long: "Saturday" };
    default:
      return { color: "default", str: "", long: "" };
  }
};
