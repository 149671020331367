import { Box, Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Table,
  Input,
  ConfigProvider,
  Button as BtnAntd,
  Tag,
  Space,
} from "antd";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import { useNavigate } from "react-router-dom";
import {
  getAllTestOrder,
  getClientInfo,
  getLabTestRequirements,
  getTestOrderById,
  getUserInfoById,
} from "store";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import AssortedFilter from "./filterOptions/assortedFilter";
import DateFilter from "./filterOptions/dateFilter";
import DirectionFilter from "./filterOptions/directionFilter";
import ClientFilter from "./filterOptions/clientFilter";
import CancelTestOrderModal from "./cancelTestOrderModal";
import { orderStatusTagColor } from "components/jkt/admin/labtest/data";
import AuditTrailModal from "./auditTrail/auditTrailModal";
import TestOrderDetailsModal from "./testOrderDetailsModal";
import decodedUserDetails from "utils/decodedUserDetails";
import useDebounce from "hooks/useDebounce";
import useCheckPermission from "hooks/useCheckPermission";
import { EmptyData } from "components/jkt/global/emptyData";
import { handleChangeSavedTestInfo } from "store/slice/savedTestInputsInfo";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import { handleChangeFields } from "store/slice/inputSlice";
import { handleChangeHandling } from "store/slice/specialHandlingSlice";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import { handleChangeOrderData } from "store/slice/orderSlice";
import { handleChangeLabtestData } from "store/slice/savedLabtestSlice";
import getTestDetailsRecursively from "components/jkt/testRequest/proxies/getTestDetailsRecursively";
import getSpecimenDataRecursively from "components/jkt/testRequest/proxies/getSpecimenDataRecursively";
import getDuplicatedSpecimenNames from "components/jkt/testRequest/proxies/getDuplicatedSpecimenNames";
import { parsedClientDetails } from "utils/parsedClientDetails";
import { useCurrentHeight } from "utils/getScreenSize";
import useTranslation from "hooks/useTranslation";
import OrderStatusFilter from "./filterOptions/orderStatusFilter";
import { isDataEmpty } from "utils/isDataEmpty";
import { getClientInfoById } from "store";
import getLabtestIdRecursively from "components/jkt/testRequest/proxies/getLabtestIdRecursively";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const GetAllTestOrder = () => {
  const dispatch = useDispatch();
  const clientId = decodedUserDetails()?.clientId;
  const [searchInput, setSearchInput] = useState("");
  const debouncedInputValue = useDebounce(searchInput);
  const [sortField, setSortField] = useState("orderDate");
  const [currentPage, setCurrentPage] = useState(1);
  const [testOrderData, setTestOrderData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [sortDirection, setSortDirection] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { handleVerifyPermission } = useCheckPermission();
  const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(0);
  const [selectedOrderDate, setSelectedOrderDate] = useState(null);
  const [selectedOrderStatuses, setSelectedOrderStatuses] = useState([]);
  const [selectedDetails, setSelectedDetails] = useState([]);
  const [selectedCreatedDetails, setSelectedCreatedDetails] = useState([]);
  const [clientDetails, setClientDetails] = useState({});
  const [cancelTestOrderData, setCancelTestOrderData] = useState({
    testOrderStatus: "",
    id: null,
    showModal: false,
  });
  const [auditTrailModalOptions, setAuditTrailModalOptions] = useState({
    testOrderId: null,
    showModal: false,
  });
  const navigate = useNavigate();
  const {
    isClientAbcLab,
    enableProcessingWindowTime,
    inClientWindowProcessingTime,
  } = parsedClientDetails();
  const clientName = clientDetails?.clientName;
  const { getTranslation } = useTranslation("TestOrderDataTable");

  //Check if the user is authorized to update a test order
  const accessRightsToModify = handleVerifyPermission(
    "Laboratory:TestOrder:Modify"
  );

  const renderEmptyData = () => (
    <EmptyData
      description="No Test Order Detected"
      action={() => navigate("/test-request")}
      btnTitle="Add New Test Order"
      renderButton={accessRightsToModify}
    />
  );

  //Fetching of client details
  useEffect(() => {
    dispatch(getClientInfo()).then((res) => {
      if (res.type === "client/get-client-info/fulfilled") {
        setClientDetails(res.payload.data);
      }
    });
  }, [clientId, dispatch]);

  const handleChangeDate = (dates, dateStrings) => {
    //Handler to change date when filtering test order
    if (dates === null) {
      setSelectedOrderDate(null);
    } else {
      setSelectedOrderDate(dateStrings);
    }
  };

  const handleClickUpdateTestOrder = (order) => {
    //Onclick function to fetch all of test order data that will be updated
    dispatch(
      getTestOrderById({
        id: order.id,
      })
    ).then((res) => {
      if (res.payload.success) {
        const orderResponse = res?.payload?.data;
        const hasAdditionalInformationTest =
          orderResponse?.testOrderDetails?.filter(
            (test) => test.patientTestRequirementDatas.length > 0
          );
        fetchAndMapLabtestDetails(orderResponse);
        dispatchChangeOrderData(orderResponse, order);
        dispatchMultiformFieldsData(orderResponse, order);
        dispatchSpecialHandlingData(orderResponse);
        dispatchPatientInputFieldsData(orderResponse);
        dispatchPatientSavedFieldsData(orderResponse);
        dispatchLabtestInfoData(hasAdditionalInformationTest);
      }
    });
    navigate("/test-request");
  };

  const dispatchLabtestInfoData = (hasAdditionalInformationTest) => {
    dispatch(
      handleChangeSavedTestInfo({
        savedTestInfo: hasAdditionalInformationTest?.map((data) => ({
          forUpdate: true,
          id: data?.testId,
          name: data?.labTest?.name,
          type: data?.labTest?.type,
        })),
      })
    );
  };

  const dispatchPatientSavedFieldsData = (orderResponse) => {
    dispatch(
      handleChangeSavedFields({
        searchPatientName: `${orderResponse?.patient?.firstname}${
          isDataEmpty(orderResponse?.patient?.lastname) ? "" : ","
        } ${
          isDataEmpty(orderResponse?.patient?.lastname)
            ? ""
            : orderResponse?.patient?.lastname
        }`,
        searchMedicalRecordNo: `${
          orderResponse?.patient?.medicalRecordNo
            ? orderResponse?.patient?.medicalRecordNo
            : ""
        }`,
        physicianCode: orderResponse?.providerId || null,
        physicianName:
          orderResponse?.provider !== null
            ? `${orderResponse?.provider?.firstName} ${
                isDataEmpty(orderResponse?.provider?.lastName)
                  ? ""
                  : orderResponse?.provider?.lastName
              }`
            : "",
        selectedPatientId: orderResponse?.patientId,
      })
    );
  };

  const dispatchPatientInputFieldsData = (orderResponse) => {
    dispatch(
      handleChangeFields({
        fullPatientName: `${orderResponse?.patient?.firstname} ${
          isDataEmpty(orderResponse?.patient?.lastname)
            ? ""
            : orderResponse?.patient?.lastname
        }`,
        gender: orderResponse?.patient?.gender,
        dateOfBirth: moment(orderResponse?.patient?.dateOfBirth),
        emails: orderResponse?.patient?.emails,
        patientEmailForDisplay: orderResponse?.patient?.emails,
      })
    );
  };

  const dispatchSpecialHandlingData = (orderResponse) => {
    dispatch(
      handleChangeHandling({
        savedHandling: orderResponse?.specialHandlings?.map((handling) => ({
          id: handling.id,
          clientId: handling.clientId,
          instructions: handling.instructions,
          displayName: handling.instructions,
        })),
      })
    );
  };

  const dispatchMultiformFieldsData = (orderResponse, order) => {
    dispatch(
      handleChangeMultiFields({
        id: order.id,
        clientWillDrawSpecimen: orderResponse?.clientWillDrawSpecimen,
        providerId: orderResponse?.providerId || null,
        patientId: orderResponse?.patientId,
        diagnosesList: orderResponse?.diagnosesList?.map((diagnosis) => ({
          id: diagnosis.id,
          displayName: diagnosis.displayName,
        })),
        specialHandlingIds: orderResponse?.specialHandlings?.map(
          (handling) => handling.id
        ),
        testOrderDetails: orderResponse?.testOrderDetails?.map((testOrder) => ({
          id: testOrder.id,
          testOrderId: order.id,
          testId: testOrder.testId,
          testIds: null,
          status: testOrder.status,
          isStat: testOrder.isStat,
          patientTestRequirementDatas: testOrder.patientTestRequirementDatas,
          relatedPatientSpecimenKeys: testOrder?.relatedPatientSpecimenKeys,
          duplicatedSpecimenKeys: orderResponse.patientSpecimens
            .filter((item) =>
              testOrder.patientSpecimens.some(
                (specimen) => item.key === specimen.key
              )
            )
            .map((data) => data.specimen.name),
        })),
      })
    );
  };

  const dispatchChangeOrderData = (orderResponse, order) => {
    dispatch(
      handleChangeOrderData({
        isOrderDataForUpdate: true,
        orderNo: isDataEmpty(orderResponse?.autoGeneratedOrderNumber)
          ? "[pending]"
          : orderResponse?.autoGeneratedOrderNumber,
        orderStatus: order.orderStatus.toLowerCase(),
        selectedTestOrderId: orderResponse.id,
        autoGeneratedOrderNumber: orderResponse?.autoGeneratedOrderNumber,
      })
    );
  };

  const fetchAndMapLabtestDetails = async (orderResponse) => {
    //For mapping the fetched data from fetchLabtestDetails function
    const updatedTestArr = await Promise.all(
      orderResponse?.testOrderDetails?.map(async (test) => {
        const labTestDetails = await fetchLabtestDetails(orderResponse, test);

        const specimenData = orderResponse?.patientSpecimens
          .filter((item) => test.relatedPatientSpecimenKeys.includes(item.key))
          .map((specimenData) => specimenData?.specimen?.name);

        return {
          ...labTestDetails,
          forUpdate: true,
          id: test.testId,
          type: test.labTest.type,
          name: test.labTest.name,
          code: test.labTest.code,
          isStatAllowed: test.isStat,
          relatedPatientSpecimenKeys: test?.relatedPatientSpecimenKeys,
          duplicatedSpecimenKeys: specimenData,
          specimen: specimenData,
        };
      })
    );

    const updatedSavedSelectedDatas = await Promise.all(
      //For saving labtest id and specimen name
      orderResponse?.testOrderDetails?.map(async (test) => {
        const labTestDetails = await saveTestsInData(test);
        return test.labTest.type === "Test"
          ? labTestDetails
          : [...labTestDetails];
      })
    );

    const updatedPatientSpecimens = await Promise.all(
      //Getting the data that will be used as basis on when to display sample collector select option
      orderResponse?.testOrderDetails?.map(async (test) => {
        const fetchedSpecimensData = await specimensData(test);
        return orderResponse?.patientSpecimens?.map((specimenData) => ({
          id: specimenData.key,
          key: specimenData.key,
          selectedCollectionSiteOption: specimenData?.collectionSite || null,
          unitOfMeasure: specimenData?.specimen.unitOfMeasure,
          specimenId: specimenData.specimenId,
          specimenQuantity: specimenData.specimenQuantity,
          notes: specimenData?.notes,
          collectionDateTime: moment(specimenData.collectionDateTime),
          collectorId: specimenData.collectorId,
          collectionSite: specimenData.collectionSite,
          status: specimenData.status,
          specimen: specimenData.specimen.name,
          specimenVolReqData: updatedTestArr?.find((test) =>
            test?.specimen.includes(specimenData?.specimen?.name)
          )?.specimenVolReqData,
          requiresBloodDraw: specimenData.requiresBloodDraw,
          requiresSampleOrigin: specimenData.requiresSampleOrigin,
          specimenExpirationInHours: fetchedSpecimensData.find(
            (data) => data.specimenId === specimenData.specimenId
          )?.specimenExpirationInHours,
        }));
      })
    );
    dispatch(
      handleChangeLabtestData({
        savedLabtestsData: updatedTestArr,
        savedLabtestsIdAndSpecimen: updatedSavedSelectedDatas.flat(),
      })
    );
    dispatch(
      handleChangeMultiFields({
        patientSpecimens: updatedPatientSpecimens[0],
      })
    );
  };

  const specimensData = async (test) => {
    //For getting all of the specimen data
    try {
      const res = await dispatch(getLabTestRequirements({ id: test.testId }));
      const testDetailsToBeAdded = await getTestDetailsRecursively(
        res.payload.data
      );

      return testDetailsToBeAdded;
    } catch (error) {
      return null;
    }
  };

  const saveTestsInData = async (test) => {
    //Recursively get labtest requirements and labtest id, if the selected type of the labtest is not Test
    try {
      const res = await dispatch(getLabTestRequirements({ id: test.testId }));
      const testIdsToBeAdded = await getLabtestIdRecursively(
        res?.payload?.data
      );

      if (test.labTest.type === "Test") {
        return {
          testId: res?.payload?.data?.id,
          specimenExpirationInHours:
            res?.payload?.data?.testDetails?.maxSpecimenAgeInHours,
          specimen: res.payload.data?.testDetails?.labTestSpecimens.map(
            (data) => data.name
          ),
        };
      } else {
        return testIdsToBeAdded.map((data) => data);
      }
    } catch (error) {
      return null;
    }
  };

  const fetchLabtestDetails = async (orderResponse, test) => {
    //Recursively fetch labtest requirements
    const isLabtestTypeOfTest = test.labTest.type === "Test";
    try {
      const res = await dispatch(getLabTestRequirements({ id: test.testId }));
      const specimenNamesData = await getDuplicatedSpecimenNames(
        res?.payload?.data
      );
      const testIdsToBeAdded = await getSpecimenDataRecursively(
        res?.payload?.data
      );
      const responseData = res?.payload?.data;

      return {
        specimenId: isLabtestTypeOfTest
          ? responseData?.testDetails?.labTestSpecimens[0]?.id
          : testIdsToBeAdded.map((data) => data.specimenId)[0],
        specimenExpirationInHours: isLabtestTypeOfTest
          ? responseData?.testDetails?.maxSpecimenAgeInHours
          : testIdsToBeAdded.find((test) =>
              Math.min(test.specimenExpirationInHours)
            )?.specimenExpirationInHours,
        testIds: isLabtestTypeOfTest
          ? null
          : testIdsToBeAdded.map((test) => test.testId),
        duplicatedSpecimenKeys: isLabtestTypeOfTest
          ? responseData?.testDetails?.labTestSpecimens?.map(
              (data) => data.name
            )
          : specimenNamesData?.map((specimen) => specimen.specimenName),
        specimen: isLabtestTypeOfTest
          ? responseData?.testDetails?.labTestSpecimens?.map(
              (data) => data.name
            )
          : [
              ...new Set(
                specimenNamesData.map((specimen) => specimen.specimenName)
              ),
            ],
        requiresBloodDraw: isLabtestTypeOfTest
          ? responseData?.testDetails?.requiresBloodDraw
          : responseData?.testDetails?.labTestSpecimens[0]?.requiresBloodDraw,
        requiresSampleOrigin: isLabtestTypeOfTest
          ? responseData?.testDetails?.requiresSampleOrigin
          : responseData?.testDetails?.labTestSpecimens[0]
              ?.requiresSampleOrigin,
        specimenVolReqData: responseData?.testDetails?.labTestSpecimens?.find(
          (specimenData) =>
            orderResponse?.patientSpecimens?.some(
              (item) => item.specimen.name === specimenData?.name
            )
        )?.testVolumeOrSizeRequirements,
      };
    } catch (error) {
      return null;
    }
  };

  const handleSearchChange = (e) => {
    //For handling the search input value
    setIsLoading(true);
    setSearchInput(e.target.value);
    setCurrentPage(1);
  };

  const handleChange = (page) => {
    setCurrentPage(page);
    setIsLoading(true);
  };
  const handleShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };
  const handleChangeSortField = (value) => {
    setSortField(value);
  };
  const handleChangeSortDirection = (value) => {
    setSortDirection(value);
  };
  const handleShowOrderModal = (selectedOrder) => {
    // For showing test order modal
    setCancelTestOrderData({
      ...cancelTestOrderData,
      testOrderStatus: selectedOrder.orderStatus,
      id: selectedOrder.id,
    });
    setIsLoading(true);
    setSelectedOrderDetails([selectedOrder]);
    setShowOrderModal(true);
    dispatch(getTestOrderById({ id: selectedOrder.id })).then((item) => {
      if (item.type === "test-orders/get-test-order-by-id/fulfilled") {
        setSelectedDetails(item?.payload?.data);
        dispatch(getUserInfoById(item?.payload?.data?.createdBy)).then(
          (userRes) => {
            const isFetchSuccess = userRes?.payload?.success;
            if (isFetchSuccess) {
              setSelectedCreatedDetails(userRes?.payload?.data);
              setIsLoading(false);
            }
          }
        );
      }
    });
  };

  const handleSelectClientId = (value) => {
    setSelectedClientId(value);
    setCurrentPage(1);
  };

  const handleCloseOrderModal = () => {
    //For closing test order modal
    handleCloseCancelTestOrderModal();
    setCancelTestOrderData({
      id: null,
      testOrderStatus: "",
      showModal: false,
    });
    setIsLoading(false);
    setSelectedDetails([]);
    setSelectedOrderDetails([]);
    setShowOrderModal(false);
  };

  const getRowClassName = (record) => {
    if (record.orderStatus === "Canceled") {
      return "line-through odd:bg-slate-300";
    }
    return "odd:bg-slate-300";
  };

  const handleShowAuditTrailModal = (selectedId) => {
    setAuditTrailModalOptions({
      testOrderId: selectedId,
      showModal: true,
    });
  };

  const handleCloseAuditTrailModal = () => {
    setAuditTrailModalOptions({
      testOrderId: null,
      showModal: false,
    });
  };

  const handleOpenCancelTestOrderModal = () => {
    setCancelTestOrderData({
      ...cancelTestOrderData,
      showModal: true,
    });
  };

  const handleCloseCancelTestOrderModal = () => {
    setCancelTestOrderData({
      ...cancelTestOrderData,
      testOrderStatus: "",
      showModal: false,
    });
  };

  const handleSelectStatus = (value) => {
    setSelectedOrderStatuses(value);
  };

  const handleFetchData = useCallback(async () => {
    // Check if fetching is already pending to avoid duplicate calls
    if (
      getAllTestOrder.pending().type ===
      "test-orders/get-all-test-order/pending"
    ) {
      setIsLoading(true);
    }

    try {
      const res = await dispatch(
        getAllTestOrder({
          clientId:
            selectedClientId === 0 || selectedClientId === undefined
              ? ""
              : selectedClientId,
          statuses: isDataEmpty(selectedOrderStatuses)
            ? null
            : selectedOrderStatuses,
          startOrderDate: isDataEmpty(selectedOrderDate)
            ? null
            : moment(selectedOrderDate[0]).format(),
          endOrderDate: isDataEmpty(selectedOrderDate)
            ? null
            : moment(selectedOrderDate[1]).format(),
          search: debouncedInputValue,
          pageSize: pageSize,
          pageNumber: currentPage,
          sortField: sortField,
          sortDirection: sortDirection,
        })
      );

      const isFetchSuccessful = res?.payload?.success;
      const responseData = res?.payload?.data;

      if (isFetchSuccessful) {
        setTotalPages(responseData?.totalPages * pageSize);
        if (isClientAbcLab) {
          const testOrderDatas = await Promise.all(
            responseData?.items?.map(async (item) => {
              const clientResponse = await dispatch(
                getClientInfoById({ id: item.clientId })
              );
              const clientResponseData = clientResponse?.payload?.data;
              return {
                ...item,
                clientName: clientResponseData?.clientName,
                inClientProcessingWindowTime:
                  clientResponseData?.clientConfig
                    ?.inClientProcessingWindowTime,
                enableProcessingWindowTime:
                  clientResponseData?.clientConfig?.enableProcessingWindowTime,
              };
            })
          );
          setTestOrderData(testOrderDatas);
        } else {
          setTestOrderData(responseData?.items);
        }
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [
    selectedClientId,
    dispatch,
    debouncedInputValue,
    pageSize,
    sortField,
    currentPage,
    sortDirection,
    selectedOrderDate,
    selectedOrderStatuses,
    isClientAbcLab,
  ]);

  //Fetching of patients
  useEffect(() => {
    handleFetchData();
  }, [dispatch, handleFetchData]);

  const renderRemainingTime = (remainingTime, orderId) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (remainingTime === 0) {
        setTestOrderData((prevItems) =>
          prevItems.map((order) =>
            order.id === orderId
              ? {
                  ...order,
                  orderStatus: "ReadyForPickup",
                }
              : order
          )
        );
        if (showOrderModal) {
          setSelectedOrderDetails((prevItems) =>
            prevItems.map((order) =>
              order.id === orderId
                ? {
                    ...order,
                    orderStatus: "ReadyForPickup",
                  }
                : order
            )
          );
        }
      }
    }, [orderId, remainingTime]);
  };

  const dataSource = testOrderData?.map((val) => {
    const isOrderForLrf = val?.isMinimalOrder;
    const patientFirstName = val?.patient?.firstname;
    const patientLastName =
      val?.patient?.lastname === null ? "" : val?.patient?.lastname;

    const patientName = isOrderForLrf
      ? `(LRF Pickup Request)`
      : `${patientFirstName} ${patientLastName}`;

    return {
      id: val?.id,
      isMinimalOrder: isOrderForLrf,
      orderDate: val?.orderDate,
      patientName: patientName,
      clientName: val?.clientName,
      orderStatus: val?.orderStatus,
      diagnosesList: val?.diagnosesList,
      specialHandlings: val?.specialHandlings,
      testOrderDetails: val?.testOrderDetails,
      specimens: val?.specimens,
      providerId: val?.providerId,
      medicalRecordNo: isOrderForLrf ? "" : val?.patient?.medicalRecordNo,
      clientId: val?.clientId,
      inClientWindowProcessingTime: val?.inClientProcessingWindowTime,
      enableProcessingWindowTime: val?.enableProcessingWindowTime,
    };
  });

  const tableColData = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 10,
    },
    {
      title: getTranslation("Client Name"),
      dataIndex: "clientName",
      key: "clientName",
      width: 30,
    },
    {
      title: getTranslation("Patient Name"),
      dataIndex: "patientName",
      key: "patientName",
      width: 30,
    },
    {
      title: getTranslation("Order Date"),
      dataIndex: "orderDate",
      key: "orderDate",
      width: 25,
      render: (value) => <p>{moment(value).format("LL")}</p>,
    },
    {
      title: getTranslation("Order Status"),
      key: "orderStatus",
      width: 20,
      render: (record) => {
        const currentDate = moment();
        const enableProcessingWindowTimeValue = isClientAbcLab
          ? record?.enableProcessingWindowTime
          : enableProcessingWindowTime;
        const inClientWindowProcessingTimeValue = isClientAbcLab
          ? record.inClientWindowProcessingTime
          : inClientWindowProcessingTime;

        const combinedOrderDateWithProcessingWindowTime = moment(
          record?.orderDate
        ).add(inClientWindowProcessingTimeValue, "minutes");
        const differenceInSeconds =
          combinedOrderDateWithProcessingWindowTime.diff(
            currentDate,
            "seconds"
          );
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Tag
              key={record?.orderStatus}
              color={orderStatusTagColor(record?.orderStatus).color}
            >
              {record?.orderStatus}
            </Tag>
            {enableProcessingWindowTimeValue &&
              record?.orderStatus === "InClientProcessing" && (
                <CountdownCircleTimer
                  strokeLinecap="butt"
                  isPlaying
                  duration={differenceInSeconds}
                  colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                  colorsTime={[7, 5, 2, 0]}
                  size={20}
                  strokeWidth={4}
                  onComplete={() => ({ delay: 1 })}
                >
                  {({ remainingTime }) =>
                    renderRemainingTime(remainingTime, record?.id)
                  }
                </CountdownCircleTimer>
              )}
          </Box>
        );
      },
    },
    {
      title: "",
      key: "",
      render: (record) => {
        const isCurrentClientMatchedOrder = isClientAbcLab
          ? clientName === record?.clientName
          : true;
        const orderStatus = record?.orderStatus?.toLowerCase();
        const allowedOrderStatuses =
          orderStatus === "readyforpickup" ||
          orderStatus === "pickedup" ||
          orderStatus === "inclientprocessing";
        const isMinimalOrder = record?.isMinimalOrder;
        const showUpdateButton =
          accessRightsToModify &&
          !isMinimalOrder &&
          allowedOrderStatuses &&
          isCurrentClientMatchedOrder;
        return (
          <Space direction="horizontal" size={2}>
            <BtnAntd
              size="small"
              type="primary"
              style={{ background: "#1677FF", color: "white" }}
              icon={<InfoIcon fontSize="inherit" />}
              onClick={() => handleShowOrderModal(record)}
            >
              {getTranslation("Details")}
            </BtnAntd>
            {showUpdateButton && (
              <BtnAntd
                onClick={() => handleClickUpdateTestOrder(record)}
                size="small"
                className="hover:bg-orange-500 bg-orange-600"
                style={{
                  border: "1px solid #ed6c02",
                  color: "white",
                }}
                icon={<EditIcon fontSize="inherit" />}
              >
                {getTranslation("Update")}
              </BtnAntd>
            )}
            <BtnAntd
              onClick={() => handleShowAuditTrailModal(record.id)}
              size="small"
              style={{
                backgroundColor: "#2C7C74",
                border: "1px solid #2C7C74",
                color: "white",
              }}
              icon={<DescriptionIcon fontSize="inherit" />}
            >
              {getTranslation("Audit Trail")}
            </BtnAntd>
          </Space>
        );
      },
      width: 40,
    },
  ];

  // For dynamic removal of client name column
  const filteredTableColData = tableColData.filter((item) =>
    !isClientAbcLab ? item.key !== "clientName" : item
  );

  return (
    <>
      <div className="flex items-start gap-4 flex-col md:flex-row w-full">
        <div>
          <Input
            placeholder={getTranslation("Search Test Order")}
            prefix={<SearchOutlined />}
            onChange={handleSearchChange}
            value={searchInput}
          />
        </div>
        <div>
          <DateFilter handleChangeDate={handleChangeDate} />
        </div>
        <div>
          <OrderStatusFilter
            selectedStatuses={selectedOrderStatuses}
            handleSelectStatus={handleSelectStatus}
          />
        </div>
        {isClientAbcLab && (
          <div>
            <ClientFilter handleSelectClientId={handleSelectClientId} />
          </div>
        )}
        <div>
          <AssortedFilter
            defaultValue={sortField}
            handleChangeSortField={handleChangeSortField}
          />
        </div>
        <div>
          <DirectionFilter
            defaultValue={sortDirection}
            handleChangeSortDirection={handleChangeSortDirection}
          />
        </div>
        <div>
          <Button
            onClick={() => navigate("/test-request")}
            startIcon={<AddOutlinedIcon />}
            variant="text"
          >
            {getTranslation("New Test Order")}
          </Button>
        </div>
      </div>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName={getRowClassName}
          columns={filteredTableColData}
          dataSource={dataSource}
          loading={isLoading}
          size="small"
          pagination={{
            total: totalPages,
            showSizeChanger: true,
            onShowSizeChange: handleShowSizeChange,
            current: currentPage,
            onChange: handleChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>
      <TestOrderDetailsModal
        handleCloseOrderModal={handleCloseOrderModal}
        showOrderModal={showOrderModal}
        cancelTestOrderData={cancelTestOrderData}
        handleOpenCancelTestOrderModal={handleOpenCancelTestOrderModal}
        selectedDetails={selectedDetails}
        selectedOrderDetails={selectedOrderDetails}
        isLoading={isLoading}
        selectedCreatedDetails={selectedCreatedDetails}
      />
      <CancelTestOrderModal
        handleCloseOrderModal={handleCloseOrderModal}
        refetchTestOrder={handleFetchData}
        onCancel={handleCloseCancelTestOrderModal}
        open={cancelTestOrderData.showModal}
        id={cancelTestOrderData.id}
      />
      <AuditTrailModal
        open={auditTrailModalOptions.showModal}
        testOrderId={auditTrailModalOptions.testOrderId}
        handleCloseModal={handleCloseAuditTrailModal}
      />
    </>
  );
};

export default GetAllTestOrder;
