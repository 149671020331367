import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getSampleCollectorLookUp } from "store";
import moment from "moment";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import useTranslation from "hooks/useTranslation";

const TestProfileSectionTwo = ({ printTestOrder }) => {
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const savedInputs = useSelector((state) => state.savedInputs);
  const savedLabtests = useSelector((state) => state.savedLabtests);
  const savedHandlingItems = useSelector((state) => state.specialHandling);
  const savedTestInputsInfo = useSelector((state) => state.savedTestInputsInfo);
  const { getTranslation } = useTranslation("RequestPage");
  const { user } = useSelector((state) => state.auth);
  const isClientWillDrawTheBlood = multiStepFormInputs.clientWillDrawSpecimen;
  const dispatch = useDispatch();

  useEffect(() => {
    if (multiStepFormInputs.patientSpecimens.length > 0) {
      dispatch(getSampleCollectorLookUp()).then((res) => {
        const firstSampleCollectorId =
          multiStepFormInputs.patientSpecimens[0]?.collectorId;
        const filteredSampleCollectorName = res?.payload?.data?.items?.find(
          (data) => data.id === firstSampleCollectorId
        )?.displayName;
        const filteredSampleCollectorType = res?.payload?.data?.items?.find(
          (data) => data.id === firstSampleCollectorId
        )?.collectorType;
        dispatch(
          handleChangeSavedFields({
            sampleCollectorName: filteredSampleCollectorName,
            sampleCollectorType: filteredSampleCollectorType,
          })
        );
      });
    }
  }, [multiStepFormInputs.patientSpecimens, dispatch]);

  const testProfileDetails = [
    {
      id: 1,
      label: getTranslation("Sample Collector"),
      value: `${
        isClientWillDrawTheBlood && savedInputs.sampleCollectorType
          ? savedInputs.sampleCollectorType + " -"
          : ""
      } ${(isClientWillDrawTheBlood && savedInputs.sampleCollectorName) || ""}`,
    },
    {
      id: 2,
      label: getTranslation("Prepared by"),
      value: `${user?.displayName || ""}`,
    },
  ];

  const isDateValid = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const renderCollectionSite = (collectionSite) => {
    if (collectionSite === null) {
      return "N/A";
    }
    return getTranslation(collectionSite);
  };

  return (
    <Grid sx={{ marginTop: "1rem" }} container>
      <Grid
        sx={{ marginRight: "auto" }}
        xs={printTestOrder ? 6 : 12}
        sm={printTestOrder ? 6 : 12}
        md={6}
        lg={6}
        xl={6}
        item
      >
        <Typography
          variant="p"
          sx={{ fontWeight: "600", color: "darkBlue.main" }}
        >
          {getTranslation("Clinical diagnosis")}
        </Typography>
        <ul className={styles["diagnosis-list"]}>
          {multiStepFormInputs.diagnosesList &&
            multiStepFormInputs.diagnosesList.map((data) => (
              <li key={data.id}>&#x2022; {data.displayName}</li>
            ))}
        </ul>
        <Box className={styles["grid-container"]}>
          {testProfileDetails.map((info) => (
            <Box key={info.id} className={styles["grid-item"]}>
              <span className={styles.label}>{info.label}</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>{info.value}</span>
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid
        xs={printTestOrder ? 6 : 12}
        sm={printTestOrder ? 6 : 12}
        md={6}
        lg={6}
        xl={6}
        item
      >
        <Typography
          variant="p"
          sx={{ fontWeight: "600", color: "darkBlue.main" }}
        >
          {getTranslation("Special Instructions")}
        </Typography>
        <ul className={styles["handling-list"]}>
          {savedHandlingItems.savedHandling &&
            savedHandlingItems.savedHandling.map((data) => {
              let specialInstruction = "";
              const statText = (
                <li key={data.id}>
                  STAT - [
                  <span className="font-bold text-sm px-1">
                    {multiStepFormInputs.testOrderDetails
                      .filter((test) => test.isStat === true)
                      .map((data) =>
                        savedLabtests.savedLabtestsData
                          .filter((savedTest) => savedTest.id === data.testId)
                          .map((savedTestData) => savedTestData.name)
                      )
                      .join(", ")}
                  </span>
                  ]
                </li>
              );

              if (data.instructions.toLowerCase() === "stat") {
                specialInstruction = statText;
              } else {
                specialInstruction = `${data.instructions}`;
              }
              return (
                <li className="flex items-center gap-1" key={data.id}>
                  &#x2022; {specialInstruction}
                </li>
              );
            })}
        </ul>
        <Typography
          variant="p"
          sx={{
            fontWeight: "600",
            color: "darkBlue.main",
          }}
        >
          {getTranslation("Additional patient information")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            marginTop: "0.5rem",
          }}
        >
          {savedTestInputsInfo.savedTestInfo.length > 0 ? (
            savedTestInputsInfo.savedTestInfo.map((test) => (
              <Box
                key={test.id}
                sx={{
                  padding: "0.6rem",
                  backgroundColor: "grayishBlue.main",
                }}
              >
                <p className={styles["additional-info-text"]}>
                  For <span className={styles["test-type"]}>{test.name}</span>{" "}
                  test
                </p>
                {test.type === "Test" &&
                  multiStepFormInputs?.testOrderDetails
                    .find((detail) => detail.testId === test.id)
                    ?.patientTestRequirementDatas[0]?.patientRequirementDataDetails?.filter(
                      (inputData) => inputData.dataValue !== ""
                    )
                    .map((requirement) => {
                      const dataValue =
                        requirement.dataValue === "true"
                          ? "Yes"
                          : requirement.dataValue === "false"
                          ? "No"
                          : isDateValid(requirement.dataValue) &&
                            requirement.dataValue.length === 10
                          ? moment(requirement.dataValue).format("LL")
                          : requirement.dataValue;

                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "0.2rem",
                          }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              color: "darkBlue.main",
                              fontSize: "0.85rem",
                            }}
                          >
                            {requirement?.description}
                          </Typography>
                          <Typography
                            variant="p"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "0.9rem",
                            }}
                          >
                            {dataValue}
                          </Typography>
                        </Box>
                      );
                    })}
              </Box>
            ))
          ) : (
            <Box
              sx={{
                backgroundColor: "grayishBlue.main",
                padding: "0.6rem",
              }}
            >
              <p className={styles["additional-info-text"]}>
                <span className={styles["test-type"]}>None</span>
              </p>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "6fr 24fr 20fr 20fr 40fr",
            marginTop: "1.2rem",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontWeight: "500",
              color: "grayishBlue.light",
            }}
          >
            No.
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontWeight: "500",
              color: "grayishBlue.light",
            }}
          >
            {getTranslation("Specimen type")}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontWeight: "500",
              color: "grayishBlue.light",
            }}
          >
            {getTranslation("Quantity")}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontWeight: "500",
              color: "grayishBlue.light",
            }}
          >
            {getTranslation("Collection Site")}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontWeight: "500",
              color: "grayishBlue.light",
            }}
          >
            {getTranslation("Notes")}
          </Typography>
        </Box>
        {multiStepFormInputs.clientWillDrawSpecimen && (
          <Box
            sx={{
              backgroundColor: "grayishBlue.main",
              paddingBottom: "1rem",
              marginTop: "0.5rem",
              display:
                multiStepFormInputs.patientSpecimens.length === 0
                  ? "none"
                  : "block",
            }}
          >
            {multiStepFormInputs.patientSpecimens &&
              multiStepFormInputs.patientSpecimens.map((data, index) => (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "5fr 26fr 20fr 20fr 40fr",
                    padding: "0.3rem 0.5rem",
                  }}
                  key={data.id}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                    }}
                    variant="p"
                  >
                    {index + 1}
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                    }}
                    variant="p"
                  >
                    {data.specimen}
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                    }}
                    variant="p"
                  >
                    {data.specimenQuantity}{" "}
                    {data.specimenQuantity !== "N/A" && data?.unitOfMeasure}
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                    }}
                    variant="p"
                  >
                    {renderCollectionSite(data.collectionSite)}
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                    }}
                    variant="p"
                  >
                    {data.notes}
                  </Typography>
                </Box>
              ))}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default TestProfileSectionTwo;
