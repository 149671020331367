import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_HEALTHCARE_PROVIDER } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const getHealthcareProvidersLookUp = createAsyncThunk(
  "healthcareProviders/get-healthcare-providers-look-up",
  async (data) => {
    try {
      const api = useAxios();
      const config = {
        params: {
          clientId: data.clientId,
          search: "",
          pageSize: 0,
          pageNumber: 1,
        },
      };
      const response = await api.get(
        URL_HEALTHCARE_PROVIDER + "/healthcare-providers-lookup",
        config
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getHealthcareProvidersLookUp };
